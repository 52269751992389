
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import ArtboardArea from "@/components/artboard-area/ArtboardArea.vue";
import Toolbar from "@/components/toolbar/Toolbar.vue";
import { useUiStore } from "@/stores/ui";
import { useArtboardStore } from "@/stores/artboards";
import { computed, onMounted, defineComponent } from "vue";
import DisplaySettings from "@/components/settings/DisplaySettings.vue";
import UiSidePanel from "@/components/ui/side-panel/SidePanel.vue";
import { addArtboard } from "@/tools/artboard";
import DrawingTimer from "@/components/drawing-timer/DrawingTimer.vue";
import UiBottomPanel from "@/components/ui/bottom-panel/BottomPanel.vue";
import {
    Artboard,
    ArtboardAreaLayout,
    ArtboardCamera,
    Load,
    Scan,
} from "@/types";
import { clone } from "@/tools/object";
import { zeroOffset } from "@/data/camera";
import cameratools from "@/tools/sketchfab/display-settings/camera";
import { config } from "@/data/config";
import { initialDisplaySettings } from "@/data/settings/settings";
import { scans } from "@/data/scans";
import DrawingTimerCountdown from "@/components/drawing-timer/DrawingTimerCountdown.vue";
import { useDrawingTimerStore } from "@/stores/drawing-timer";
import useBreakpoint from "@/composables/use-breakpoint";

export default defineComponent({
    components: {
        DrawingTimerCountdown,
        UiBottomPanel,
        DrawingTimer,
        UiSidePanel,
        DisplaySettings,
        Toolbar,
        ArtboardArea,
        Breadcrumbs,
    },
    setup() {
        const uiStore = useUiStore();
        const artboardStore = useArtboardStore();
        const drawingTimerStore = useDrawingTimerStore();

        const { mobile, wideDrawingTimer } = useBreakpoint();

        const applyLayout = (layout: ArtboardAreaLayout) => {
            artboardStore.setLayout(layout);
        };

        const applyCamera = (camera: ArtboardCamera) => {
            artboardStore.general.camera = camera;
        };

        const applyArtboards = (artboards: Artboard[]) => {
            for (const artboard of artboards) {
                addArtboard(
                    artboard.scan,
                    artboard.displaySettings,
                    artboard.offset,
                    false
                );
            }
        };

        const applyLoadCollection = (load: Load) => {
            applyLayout(load.general.layout);
            applyCamera(load.general.camera);
            applyArtboards(load.artboards);
        };

        const listenToEvents = () => {
            // Listen to incoming messages from the parent window
            // 1 - load collection
            // 2 - add scan
            window.addEventListener("message", (event) => {
                const blockedOrigins = ["https://sketchfab.com"];
                if (blockedOrigins.includes(event.origin)) {
                    return;
                }
                // console.group("3D app receives message");
                // console.log("Origin", event.origin);
                // console.log("Action", event.data.action);
                // console.log("Data", event.data.data);
                switch (event.data.action) {
                    case "load-collection":
                        applyLoadCollection(event.data.data as Load);
                        break;
                    case "add-scan":
                        addArtboard(
                            event.data.data as Scan,
                            artboardStore.getGeneralDisplaySettings(),
                            clone(zeroOffset),
                            uiStore.replaceMode
                        );
                        break;
                    default:
                        break;
                }
                console.groupEnd();
            });
        };

        const listenForKeystrokes = () => {
            window.addEventListener("keydown", (event) => {
                if (uiStore.liveDrawingPanel) {
                    if (event.key === "ArrowLeft") {
                        drawingTimerStore.previousFrame();
                    }
                    if (event.key === "ArrowRight") {
                        drawingTimerStore.nextFrame();
                    }
                }
                if (event.shiftKey) {
                    if (event.key === "C") {
                        if (artboardStore.current) {
                            cameratools
                                .captureCamera(artboardStore.current.api)
                                .then((cam) => {
                                    console.log(JSON.stringify(cam, null, 2));
                                });
                        } else {
                            console.log("Please focus an artboard first");
                        }
                    }
                }
            });
        };
        const fullscreen = computed(() => uiStore.fullscreenMode);
        const showBreadcrumbs = computed(() => {
            return (
                !fullscreen.value &&
                (!mobile.value ||
                    (!uiStore.displaySettingsPanel &&
                        !uiStore.liveDrawingPanel))
            );
        });

        const showToolbar = computed(() => {
            return (
                (!fullscreen.value || uiStore.liveDrawingPanel) &&
                (!mobile.value ||
                    (!uiStore.displaySettingsPanel &&
                        !uiStore.liveDrawingPanel))
            );
        });

        onMounted(() => {
            if (config.standAloneMode) {
                artboardStore.setLayout("row");
                for (const i of [0, 1, 2]) {
                    addArtboard(
                        scans[i],
                        clone(initialDisplaySettings),
                        clone(zeroOffset),
                        false
                    );
                }
            } else {
                listenToEvents();
            }
            listenForKeystrokes();
        });

        return {
            uiStore,
            drawingTimerStore,
            mobile,
            wideDrawingTimer,
            fullscreen,
            showBreadcrumbs,
            showToolbar,
        };
    },
});
