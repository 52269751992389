
import { computed, defineComponent, PropType } from "vue";
import { zeroOffset } from "@/data/camera";
import { updateCameraForArtboard } from "@/tools/sketchfab/main";
import { hasOffset } from "@/tools/sketchfab/general/camera";
import { useArtboardStore } from "@/stores/artboards";
import UiIconButton from "@/components/ui/icon-button/IconButton.vue";
import { Artboard } from "@/types";
import { config } from "@/data/config";

export default defineComponent({
    name: "ArtboardFooterTools",
    components: { UiIconButton },
    props: {
        artboard: {
            type: Object as PropType<Artboard>,
            required: true,
        },
    },
    setup(props) {
        const store = useArtboardStore();

        const removeOffset = () => {
            store.artboardSetOffset(props.artboard.id, zeroOffset);
            if (!config.disableSketchfab) {
                updateCameraForArtboard(props.artboard);
            }
        };

        const artboardHasOffset = computed(() => {
            return hasOffset(props.artboard.offset, zeroOffset);
        });

        return {
            removeOffset,
            artboardHasOffset,
        };
    },
});
