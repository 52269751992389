
import { computed, defineComponent, PropType } from "vue";
import { Tool, DisplaySettingsValue } from "@/types";
import { useArtboardStore } from "@/stores/artboards";
import UiSlider from "@/components/ui/slider/Slider.vue";
import UiCheckbox from "@/components/ui/checkbox/Checkbox.vue";
import UiList from "@/components/ui/list/List.vue";
import { nonOrthLensAngle, orthoLensAngle } from "@/data/constants";

export default defineComponent({
    name: "DisplaySettingsTool",
    components: { UiSlider, UiCheckbox, UiList },
    props: {
        tool: {
            type: Object as PropType<Tool>,
            required: true,
        },
    },
    setup(props) {
        const store = useArtboardStore();

        const value = computed(() => {
            return store.getDisplaySettingsValue(props.tool.key);
        });

        const update = (value: DisplaySettingsValue) => {
            if (props.tool.key === "lensAngleOrtho") {
                const lensAngleValue = value
                    ? orthoLensAngle
                    : nonOrthLensAngle;
                store.setDisplaySettingsValue("lensAngle", lensAngleValue);
            } else {
                store.setDisplaySettingsValue(props.tool.key, value);
            }
        };

        return {
            value,
            update,
        };
    },
});
