
import { computed, defineComponent } from "vue";
import ExpansionTitle from "@/components/settings/ExpansionTitle.vue";
import useReset from "@/composables/use-reset";
import ResetButton from "@/components/settings/ResetButton.vue";
import DisplaySettingsTool from "@/components/settings/DisplaySettingsTool.vue";
import { useArtboardStore } from "@/stores/artboards";
import { Tool } from "@/types";

export default defineComponent({
    name: "DisplaySettingsSection",
    components: { DisplaySettingsTool, ResetButton, ExpansionTitle },
    props: {
        section: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const store = useArtboardStore();

        const activeTools = computed(() => {
            return props.section.tools.filter(
                (tool: Tool) => !store.current || tool.forIndividualArtboard
            );
        });

        const { reset, resetDisabled, hasOffset } = useReset(
            props.section.tools
        );

        return {
            reset,
            resetDisabled,
            hasOffset,
            activeTools,
        };
    },
});
