import { ArtboardCamera } from "@/types";

export const initialCamera: ArtboardCamera = {
    latlon: {
        lon: 205,
        lat: 25,
    },
    radius: 7,
    target: [0, 0, 1],
};

export const zeroOffset: ArtboardCamera = {
    latlon: {
        lon: 0,
        lat: 0,
    },
    radius: 0,
    target: [0, 0, 0],
};
