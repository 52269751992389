import { Environment } from "@/types";

const environments: Environment[] = [
    {
        id: 1,
        name: "Studio soft",
        payload: {
            uid: "08468d6fcf9846039cc3e51accddd9f9",
            exposure: 1,
            shadowEnabled: false,
            lightIntensity: 0.5,
            rotation: 0.4,
        },
    },
    {
        id: 2,
        name: "Industrial room",
        payload: {
            uid: "80fba9759ab94307a782f38d359b8ea8",
            exposure: 1.4,
            shadowEnabled: false,
            lightIntensity: 0.5,
            rotation: 5.8,
        },
    },
    {
        id: 3,
        name: "Studio",
        payload: {
            uid: "df380da788ee444885722735039b0c09",
            exposure: 1.8,
            shadowEnabled: false,
            lightIntensity: 0.5,
            rotation: 1.5,
        },
    },
];

export default environments;
