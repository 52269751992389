import { PostProcessing } from "@/types";

const postprocessings: PostProcessing[] = [
    {
        id: 1,
        name: "Scott default",
        payload: {
            enable: true,
            ssaoEnable: true,
            ssaoRadius: 2,
            ssaoIntensity: 0.75,
            ssaoBias: 0.5,
            vignetteEnable: false,
            vignetteAmount: 0.25,
            vignetteHardness: 0.8,
        },
    },
];

export default postprocessings;
