import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3778e148"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "DrawingAidToggles" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent('ui-' + _ctx.boundingBoxTool.type), _mergeProps({
      "data-key": _ctx.boundingBoxTool.key,
      alternativeIconSet: _ctx.boundingBoxIconSet
    }, _ctx.boundingBoxTool.options, {
      value: _ctx.boundingBoxValue,
      onToolUpdate: _ctx.boundingBoxUpdate
    }), null, 16, ["data-key", "alternativeIconSet", "value", "onToolUpdate"])),
    (_openBlock(), _createBlock(_resolveDynamicComponent('ui-' + _ctx.groundPlaneTool.type), _mergeProps({
      "data-key": _ctx.groundPlaneTool.key,
      alternativeIconSet: _ctx.groundPlaneIconSet
    }, _ctx.groundPlaneTool.options, {
      value: _ctx.groundPlaneValue,
      onToolUpdate: _ctx.groundPlaneUpdate
    }), null, 16, ["data-key", "alternativeIconSet", "value", "onToolUpdate"]))
  ]))
}