
import { defineComponent, PropType } from "vue";
import { MixedState, MixedStateValueSlider } from "@/types";
import useTool from "@/composables/use-tool";

export default defineComponent({
    name: "UiSlider",
    components: {},
    props: {
        title: {
            type: String,
            required: true,
        },
        min: {
            type: Number,
            required: true,
        },
        max: {
            type: Number,
            required: true,
        },
        step: {
            type: Number,
            required: true,
        },
        hideInput: {
            type: Boolean,
            required: false,
            default: false,
        },
        direction: {
            type: String as PropType<"horizontal" | "vertical">,
            required: false,
            default: "horizontal",
        },
        theme: {
            type: String as PropType<"regular" | "dark">,
            required: false,
            default: "regular",
        },
        value: {
            type: [Number, String] as PropType<number | MixedState>,
            required: true,
        },
        // currently only works for sliders with hideInput = true
        centerPoint: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props, { emit }) {
        const mixedV: MixedStateValueSlider = (props.max + props.min) / 2;
        const { localValue, color, isMixed } = useTool(props, emit, mixedV);

        return {
            localValue,
            isMixed,
            color,
        };
    },
});
