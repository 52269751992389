import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e8337f3"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-size", "disabled", "data-capitalize", "data-is-active", "data-variant", "data-vertical"]
const _hoisted_2 = ["data-border", "data-size"]
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = {
  key: 0,
  class: "IconButton__label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_ui_deviation = _resolveComponent("ui-deviation")!

  return (_openBlock(), _createElementBlock("button", {
    class: "IconButton",
    "data-size": _ctx.size,
    disabled: _ctx.disabled,
    "data-capitalize": _ctx.capitalize,
    "data-is-active": _ctx.isActive,
    "data-variant": _ctx.variant,
    "data-vertical": _ctx.vertical
  }, [
    _createElementVNode("div", {
      class: "IconButton__border",
      "data-border": _ctx.border,
      "data-size": _ctx.size
    }, [
      (_ctx.withLocalIcon)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: 'icons/' + _ctx.iconId + '.svg',
            alt: _ctx.iconId
          }, null, 8, _hoisted_3))
        : (_openBlock(), _createBlock(_component_v_icon, {
            key: 1,
            icon: 'fas fa-' + _ctx.iconId,
            size: _ctx.size
          }, null, 8, ["icon", "size"]))
    ], 8, _hoisted_2),
    (_ctx.label.length)
      ? (_openBlock(), _createElementBlock("label", _hoisted_4, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (_ctx.hasDeviation)
      ? (_openBlock(), _createBlock(_component_ui_deviation, { key: 1 }))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}