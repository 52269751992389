import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8f9bcea2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-vertical", "data-small-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createElementBlock("div", {
    class: "Select",
    "data-vertical": _ctx.vertical,
    "data-small-label": _ctx.smallLabel
  }, [
    _createElementVNode("label", null, _toDisplayString(_ctx.label), 1),
    _createVNode(_component_v_select, {
      items: _ctx.options,
      modelValue: _ctx.localValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event))
    }, null, 8, ["items", "modelValue"])
  ], 8, _hoisted_1))
}