import { Coordinate, SceneNode, SketchfabApi } from "@/types";
import { getMatrixFromRotation } from "../general/transform";

let nodeMap: { [key: string]: SceneNode } = {};

const getNodeMap = (api: SketchfabApi) => {
    api.getNodeMap((err: Error, nodes: { [key: string]: SceneNode }) => {
        if (err) {
            console.log(err.stack);
        } else {
            nodeMap = nodes;
        }
    });
};

const setObjectVisibility = function (
    api: SketchfabApi,
    objectNames: string[],
    doShow: boolean
): Promise<void> {
    return new Promise<void>((resolve) => {
        if (api) {
            const matchedNodeArray: SceneNode[] = [];

            // first find the nodes
            for (const name of objectNames) {
                Object.keys(nodeMap).forEach((key) => {
                    const node = nodeMap[key];
                    if (node.type === "MatrixTransform" && node.name === name) {
                        matchedNodeArray.push(node);
                    }
                });
            }

            const promises = [];
            for (const node of matchedNodeArray) {
                const promise = new Promise<void>((resolve) => {
                    if (doShow) api.show(node.instanceID, () => resolve());
                    else api.hide(node.instanceID, () => resolve());
                });
                promises.push(promise);
            }
            Promise.all(promises).then(() => resolve());
        } else resolve();
    });
};

const getRootNode = (api: SketchfabApi): Promise<number> => {
    return new Promise((resolve) => {
        api.getRootMatrixNode((err: Error, instanceID: number) => {
            resolve(instanceID);
        });
    });
};

const setMatrixFromRotation = (
    api: SketchfabApi,
    instanceID: number | null,
    coord: Coordinate
): Promise<void> => {
    if (!instanceID) return Promise.resolve();
    return new Promise((resolve) => {
        const matrix = getMatrixFromRotation(coord);
        api.setMatrix(instanceID, matrix, () => {
            resolve();
        });
    });
};

export default {
    getRootNode,
    setMatrixFromRotation,
    getNodeMap,
    setObjectVisibility,
};
