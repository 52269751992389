
import { computed, defineComponent } from "vue";
import UiDeviation from "@/components/ui/deviation/Deviation.vue";

export default defineComponent({
    name: "UiIconButton",
    components: { UiDeviation },
    props: {
        iconId: {
            type: String,
            required: true,
        },
        hasDeviation: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: "medium",
            validator(value: string) {
                return [
                    "x-small",
                    "small",
                    "medium",
                    "large",
                    "x-large",
                ].includes(value);
            },
        },
        label: {
            type: String,
            required: false,
            default: "",
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        capitalize: {
            type: Boolean,
            required: false,
            default: false,
        },
        isActive: {
            type: Boolean,
            required: false,
            default: false,
        },
        border: {
            type: Boolean,
            required: false,
            default: false,
        },
        variant: {
            type: String,
            default: "primary",
        },
        vertical: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props) {
        const localIcons = [
            "grid",
            "row",
            "focus",
            "settings",
            "reset",
            "add",
            "replace",
            "save-to-collection",
            "favorite",
            "help",
            "timer",
            "light",
            "environment",
            "material",
            "camera",
            "rotate_90",
            "refresh",
            "close",
            "fullscreen",
            "delete_outline",
            "reset-position",
            "triangle-down",
            "caret-right",
            "caret-left",
            "play",
            "remove",
            "reset-navigation",
            "video_settings",
            "circle-grid-on",
            "circle-grid-off",
            "circle-grid-mixed",
            "circle-guide-on",
            "circle-guide-off",
            "circle-guide-mixed",
        ];

        const withLocalIcon = computed(() => localIcons.includes(props.iconId));

        return {
            localIcons,
            withLocalIcon,
        };
    },
});
