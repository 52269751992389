import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-377b48f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-only-main"]
const _hoisted_2 = { class: "HorizontalVertical__main" }
const _hoisted_3 = {
  key: 0,
  class: "HorizontalVertical__bottom-left"
}
const _hoisted_4 = {
  key: 1,
  class: "HorizontalVertical__bottom-right"
}
const _hoisted_5 = {
  key: 2,
  class: "HorizontalVertical__vertical"
}
const _hoisted_6 = {
  key: 3,
  class: "HorizontalVertical__corner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "HorizontalVertical",
    "data-only-main": !_ctx.laptop
  }, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "main", {}, undefined, true)
    ]),
    (_ctx.laptop)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "bottom-left", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.laptop)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "bottom-right", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.laptop)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "vertical", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.laptop)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "corner", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}