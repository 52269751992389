
import { computed, defineComponent, watch } from "vue";
import DrawingTimerDurationSlider from "@/components/drawing-timer/DrawingTimerDurationSlider.vue";
import UiIconButton from "@/components/ui/icon-button/IconButton.vue";
import { useDrawingTimerStore } from "@/stores/drawing-timer";
import { useUiStore } from "@/stores/ui";
import { useArtboardStore } from "@/stores/artboards";
import DrawingTimerFrameControl from "@/components/drawing-timer/DrawingTimerFrameControl.vue";
import {
    getCamerasForCameraPath,
    visualizeCameras,
} from "@/tools/sketchfab/drawing-timer";
import { SketchfabCamera } from "@/types";
import { config } from "@/data/config";
import useTimer from "@/composables/use-timer";
import DrawingTimerTools from "@/components/settings/DrawingTimerTools.vue";
import UiButton from "@/components/ui/button/Button.vue";
import useBreakpoint from "@/composables/use-breakpoint";
import DrawingTimerClose from "@/components/drawing-timer/DrawingTimerClose.vue";
import DrawingTimerToolsPopup from "@/components/drawing-timer/DrawingTimerToolsPopup.vue";

export default defineComponent({
    name: "DrawingTimer",
    components: {
        DrawingTimerToolsPopup,
        DrawingTimerClose,
        UiButton,
        DrawingTimerTools,
        DrawingTimerFrameControl,
        UiIconButton,
        DrawingTimerDurationSlider,
    },
    props: undefined,
    setup() {
        const uiStore = useUiStore();
        const store = useDrawingTimerStore();
        const artboardStore = useArtboardStore();
        const { mobile, wideDrawingTimer, semiWideDrawingTimer } =
            useBreakpoint();

        const isSpherical = computed(() => store.cameraPath === "Spherical");

        const tick = () => {
            store.nextFrame();
            updateCameraLookAt();
        };

        const { start, stop } = useTimer(tick);

        const startTimer = () => {
            updateCameraLookAt();
            removeCamerasFromArtboard();
            start();
        };

        const stopTimer = () => {
            stop();
            updateLayout();
        };

        let cameras: SketchfabCamera[] = [];

        const updateLayout = () => {
            if (!config.disableSketchfab) {
                removeCamerasFromArtboard();
                createCamerasOnArtboard();
            }
        };

        const createCamerasOnArtboard = async () => {
            const current = artboardStore.current;
            if (current) {
                const radius =
                    artboardStore.general.camera.radius + current.offset.radius;
                cameras = await getCamerasForCameraPath(
                    store.cameraPath,
                    store.cameraOrder,
                    store.density,
                    radius,
                    current.center || [0, 0, 0],
                    current.api
                );
                visualizeCameras(current.api, true, cameras);
                store.currentFrame = 1;
            }
        };

        const removeCamerasFromArtboard = () => {
            // we need to clean up the cameras on the old artboard
            // but from here we don't know which one it is,
            // so we just clean up all of them
            for (const artboard of artboardStore.selected) {
                if (artboard.api) {
                    // for hiding the cameras are irrelevant
                    visualizeCameras(artboard.api, false, []);
                }
            }
        };

        const updateCameraLookAt = () => {
            const current = artboardStore.current;
            if (current && current.api && !config.disableSketchfab) {
                const currentCamera = cameras[store.currentFrame - 1];
                if (currentCamera) {
                    const api = current.api;
                    api.setCameraLookAt(
                        currentCamera.position,
                        JSON.parse(JSON.stringify(currentCamera.target)),
                        0
                    );
                }
            }
        };

        const close = () => {
            // removeCamerasFromArtboard();
            if (uiStore.liveDrawingPanel) artboardStore.deselectArtboard();
            uiStore.liveDrawingPanel = false;
        };

        watch(
            () => [
                store.duration,
                store.cameraPath,
                store.density,
                store.cameraOrder,
                artboardStore.current,
                uiStore.liveDrawingPanel,
            ],
            () => {
                if (store.isPlaying || uiStore.liveDrawingPanel) {
                    stop();
                }
            }
        );

        watch(
            () => store.currentFrame,
            () => {
                if (store.isPlaying) {
                    startTimer();
                } else {
                    updateCameraLookAt();
                }
            }
        );

        watch(
            // While the panel is open, redraw the cameras when one of
            // the settings change or when we switch artboard
            // When the panel closes, remove them
            () => [
                artboardStore.current,
                store.cameraPath,
                store.density,
                store.cameraOrder,
                uiStore.liveDrawingPanel,
            ],
            () => {
                if (uiStore.liveDrawingPanel === true) {
                    store.currentFrame = 1;
                    updateLayout();
                } else if (uiStore.liveDrawingPanel === false) {
                    close();
                    removeCamerasFromArtboard();
                }
            }
        );

        watch(
            () => artboardStore.general.layout,
            () => {
                if (
                    artboardStore.general.layout !== "focus" &&
                    uiStore.liveDrawingPanel
                ) {
                    close();
                    removeCamerasFromArtboard();
                }
            }
        );

        return {
            startTimer,
            stopTimer,
            store,
            close,
            updateLayout,
            mobile,
            wideDrawingTimer,
            isSpherical,
            semiWideDrawingTimer,
        };
    },
});
