import { defineStore } from "pinia";

interface UiState {
    displaySettingsPanel: boolean;
    liveDrawingPanel: boolean;
    fullscreenMode: boolean;
    replaceMode: boolean;
}

export const useUiStore = defineStore("ui", {
    state: () => {
        return {
            displaySettingsPanel: false,
            liveDrawingPanel: false,
            fullscreenMode: false,
            replaceMode: false,
        } as UiState;
    },
    getters: {},
    actions: {},
});
