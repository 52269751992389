import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1fddd0b3"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-direction"]
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "SidePanel__content" }
const _hoisted_4 = { class: "SidePanel__main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["SidePanel", { 'SidePanel--active': _ctx.active }]),
    style: _normalizeStyle({ '--size': _ctx.size + 'px' }),
    "data-direction": _ctx.direction
  }, [
    _createElementVNode("div", {
      id: _ctx.panelId,
      class: "SidePanel__panel"
    }, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "panel", {}, undefined, true)
      ])
    ], 8, _hoisted_2),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "main", {}, undefined, true)
    ])
  ], 14, _hoisted_1))
}