
import { defineComponent } from "vue";

export default defineComponent({
    name: "UiBottomPanel",
    props: {
        active: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup() {
        return {};
    },
});
