
import { defineComponent } from "vue";
import { Tool } from "@/types";
import DisplaySettingsTool from "@/components/settings/DisplaySettingsTool.vue";

export default defineComponent({
    name: "DisplaySettingsLightLongitude",
    components: { DisplaySettingsTool },
    setup() {
        const tool: Tool = {
            type: "slider",
            key: "longitude",
            options: {
                min: 0,
                max: 360,
                step: 1,
                title: "",
                hideInput: true,
                centerPoint: true,
            },
            forIndividualArtboard: false,
        };

        return {
            tool,
        };
    },
});
