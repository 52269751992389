
import { useArtboardStore } from "@/stores/artboards";
import { useFullscreen } from "@vueuse/core";
import { defineComponent, computed, watch } from "vue";
import { updateCamera } from "@/tools/sketchfab/main";
import { initialCamera, zeroOffset } from "@/data/camera";
import { useUiStore } from "@/stores/ui";
import { hasOffset as hasPositionOffset } from "@/tools/sketchfab/general/camera";
import UiIconButton from "@/components/ui/icon-button/IconButton.vue";
import useReset from "@/composables/use-reset";
import { allTools } from "@/data/sections";
import LayoutPicker from "@/components/artboard-area/layout/LayoutPicker.vue";
import ArtboardAreaLabel from "@/components/artboard-area/ArtboardAreaLabel.vue";
import useBreakpoint from "@/composables/use-breakpoint";
import { config } from "@/data/config";

export default defineComponent({
    name: "ArtboardAreaTools",
    components: { ArtboardAreaLabel, LayoutPicker, UiIconButton },
    setup() {
        const store = useArtboardStore();
        const uiStore = useUiStore();

        const { laptop, wideArtboardHeader } = useBreakpoint();

        const { hasOffset } = useReset(allTools);

        const el = document.getElementById("full-screen-node");
        const { isFullscreen, enter, exit } = useFullscreen(el);

        const reset = () => {
            store.general.camera = initialCamera;
            for (const artboard of store.selected) {
                store.artboardSetOffset(artboard.id, zeroOffset);
                if (!config.disableSketchfab) {
                    updateCamera(artboard.api, store.general.camera);
                }
            }
        };

        const openDisplaySettings = () => {
            uiStore.displaySettingsPanel = true;
            uiStore.liveDrawingPanel = false;
        };

        const deselectArtboard = () => {
            uiStore.liveDrawingPanel = false;
            store.deselectArtboard();
        };

        const globalHasOffset = computed(() => {
            return hasPositionOffset(store.general.camera, initialCamera);
        });

        watch(
            () => isFullscreen.value,
            () => (uiStore.fullscreenMode = isFullscreen.value)
        );

        return {
            reset,
            openDisplaySettings,
            store,
            globalHasOffset,
            hasOffset,
            enter,
            isFullscreen,
            exit,
            laptop,
            wideArtboardHeader,
            deselectArtboard,
        };
    },
});
