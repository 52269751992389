import { DisplaySettings, MixedState } from "@/types";
import materials from "@/data/settings/materials";
import environments from "@/data/settings/environments";
import { orthoLensAngle } from "@/data/constants";

export const mixedState: MixedState = "mixed";

const initialLensAngle = 25;
export const initialDisplaySettings: DisplaySettings = {
    intensity: 0.75,
    latitude: 40,
    longitude: 146,
    shadows: true,
    material: materials[0].id,
    lensAngle: initialLensAngle,
    lensAngleOrtho: (initialLensAngle as number) === orthoLensAngle,
    rotationX: 0,
    rotationY: 0,
    rotationZ: 0,
    environment: environments[0].id,
    environmentRotation: environments[0].payload.rotation
        ? environments[0].payload.rotation
        : 0,
    environmentIntensity: environments[0].payload.lightIntensity
        ? environments[0].payload.lightIntensity
        : 0,
    shadowEnabled: environments[0].payload.shadowEnabled
        ? environments[0].payload.shadowEnabled
        : false,
    lightIntensity: environments[0].payload.lightIntensity
        ? environments[0].payload.lightIntensity
        : 0.5,
    displayBackground: true,
    boundingBox: false,
    groundPlane: true,
};
