
import { defineComponent } from "vue";
import { Tool } from "@/types";
import DisplaySettingsTool from "@/components/settings/DisplaySettingsTool.vue";

export default defineComponent({
    name: "DisplaySettingsLightLatitude",
    components: { DisplaySettingsTool },
    setup() {
        const tool: Tool = {
            type: "slider",
            key: "latitude",
            options: {
                min: -90,
                max: 90,
                step: 1,
                title: "",
                hideInput: true,
                centerPoint: true,
                direction: "vertical",
            },
            forIndividualArtboard: false,
        };

        return {
            tool,
        };
    },
});
