import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["aria-disabled"]
const _hoisted_2 = { class: "DisplaySettings__head" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon_button = _resolveComponent("ui-icon-button")!
  const _component_display_settings_section = _resolveComponent("display-settings-section")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!

  return (_openBlock(), _createElementBlock("div", {
    class: "DisplaySettings",
    "aria-disabled": _ctx.isLoading
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.settingsTitle), 1),
      _createVNode(_component_ui_icon_button, {
        onClick: _ctx.close,
        "icon-id": "close",
        size: "big"
      }, null, 8, ["onClick"])
    ]),
    _createVNode(_component_v_expansion_panels, {
      multiple: _ctx.laptop,
      modelValue: _ctx.m,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.m) = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.theSections, (section, index) => {
          return (_openBlock(), _createBlock(_component_display_settings_section, {
            key: index,
            section: section
          }, null, 8, ["section"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["multiple", "modelValue"])
  ], 8, _hoisted_1))
}