import {
    CameraOrder,
    CameraPath,
    Coordinate,
    SketchfabApi,
    SketchfabCamera,
} from "@/types";
import camera from "../display-settings/camera";
import {
    getLatLonFromPos,
    getPositionFromLatLon,
    getPositionFromVector,
} from "../general/transform";
import annotations from "../display-settings/annotations";
import {
    radialCameraVectors,
    sphericalCameraVectors,
} from "@/data/drawing-timer";

function shuffle(array: any[]) {
    let currentIndex = array.length,
        randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex],
            array[currentIndex],
        ];
    }

    return array;
}

export const getCamerasForCameraPath = async (
    cameraPath: CameraPath,
    cameraOrder: CameraOrder,
    frameCountIndex: string,
    radius: number,
    center: Coordinate,
    api: SketchfabApi
): Promise<SketchfabCamera[]> => {
    let cameras: SketchfabCamera[] = [];
    const camData = await camera.captureCamera(api);
    switch (cameraPath) {
        case "Spherical":
            cameras = getCamerasForSphericalPath(frameCountIndex, radius, [
                center[0],
                center[1],
                camData.target[2],
            ]);
            break;
        case "Radial":
        default:
            cameras = getCamerasForRadialPath(
                frameCountIndex,
                radius,
                [center[0], center[1], camData.target[2]],
                camData.latlon.lat,
                camData.latlon.lon
            );
    }
    if (cameraOrder === "Random") {
        cameras = shuffle(cameras);
    }

    return cameras;
};

const getCamerasForSphericalPath = (
    frameCountIndex: string,
    radius: number,
    center: Coordinate
) => {
    const vectors = sphericalCameraVectors[frameCountIndex];
    const cameras = vectors.map((vector) => {
        const position = getPositionFromVector(vector, radius * 2, center);
        const camera: SketchfabCamera = {
            position,
            target: center,
        };
        return camera;
    });
    return cameras;
};

const getCamerasForRadialPath = (
    frameCountIndex: string,
    radius: number,
    center: Coordinate,
    lat: number,
    lon: number
) => {
    const vectors = radialCameraVectors[frameCountIndex];
    const cameras = vectors.map((vector) => {
        const latlon = getLatLonFromPos(vector as Coordinate, center);
        const position = getPositionFromLatLon(
            { lat: lat, lon: latlon.lon + lon },
            radius,
            center
        );
        const camera: SketchfabCamera = {
            position,
            target: center,
        };
        return camera;
    });
    return cameras;
};

const getRadiusMultiplier = (fov: number): number => {
    const angles: number[] = [0.1, 15, 25, 35, 50, 60, 70, 90, 120];
    const values: number[] = [0.28, 0.32, 0.38, 0.52, 0.65, 0.78, 0.85, 0.9];
    if (fov === 0.1) {
        return 0.28;
    } else if (fov <= 15) {
        return 0.28;
    } else if (fov <= 25) {
        return 0.32;
    } else if (fov <= 35) {
        return 0.38;
    } else if (fov <= 50) {
        return 0.52;
    } else if (fov <= 60) {
        return 0.65;
    } else if (fov <= 70) {
        return 0.78;
    } else if (fov <= 90) {
        return 0.85;
    } else {
        return 0.9;
    }
};

export const visualizeCameras = (
    api: SketchfabApi,
    doShow: boolean,
    cameras: SketchfabCamera[]
) => {
    if (doShow === false) {
        annotations.clearAnnotations(api);
    } else {
        // Create a cubic spline interpolation
        camera.getFov(api).then((fov) => {
            const radiusMultiplier = getRadiusMultiplier(fov);
            const promises: Promise<void>[] = [];
            cameras.forEach((camera) => {
                const pos: Coordinate = [
                    camera.target[0] +
                        (camera.position[0] - camera.target[0]) *
                            radiusMultiplier,
                    camera.target[1] +
                        (camera.position[1] - camera.target[1]) *
                            radiusMultiplier,
                    camera.target[2] +
                        (camera.position[2] - camera.target[2]) *
                            radiusMultiplier,
                ];
                const annotationPromise = annotations.createAnnotation(
                    api,
                    pos,
                    camera.position,
                    JSON.parse(JSON.stringify(camera.target))
                );
                promises.push(annotationPromise);
            });
            Promise.all(promises).then(() => {
                // The annotations that are shown for the drawing timer
                // shouldn't move the camera when clicked. This method only works for annotations
                // that are present. That's why we execute it after the annotations
                // have been created
                api.setAnnotationCameraTransition(true, true);
            });
        });
    }
};
