import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "Breadcrumbs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_breadcrumb = _resolveComponent("breadcrumb")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (breadcrumb, index) => {
      return (_openBlock(), _createBlock(_component_breadcrumb, {
        key: index,
        breadcrumb: breadcrumb,
        "with-arrow": index < _ctx.breadcrumbs.length - 1
      }, null, 8, ["breadcrumb", "with-arrow"]))
    }), 128))
  ]))
}