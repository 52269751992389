import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e9dacb7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "DrawingTimerToolsPopup__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_item = _resolveComponent("v-card-item")!
  const _component_drawing_timer_tools = _resolveComponent("drawing-timer-tools")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_ui_icon_button = _resolveComponent("ui-icon-button")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    color: "black",
    class: "DrawingTimerToolsPopup"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(" Playback settings ")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_drawing_timer_tools, { vertical: "" })
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", {
        class: "DrawingTimerToolsPopup__close",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      }, [
        _createVNode(_component_ui_icon_button, {
          id: "close-drawing-timer",
          "icon-id": "close",
          size: "big"
        })
      ])
    ]),
    _: 1
  }))
}