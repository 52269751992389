import { Scan } from "@/types";

export const scans: Scan[] = [
    {
        sketchfabId: "e5826dcbf5bf430cbfc666f97641f7cc",
        title: "Muscular male legs lunge 1",
        category: {
            title: "Life Drawing Poses",
            id: 1,
        },
        set: {
            title: "Muscular Male",
            id: 100,
        },
    },
    {
        sketchfabId: "7205a424c1784e75aa8e8438208cabae",
        title: "Skinny male lunge 1",
        category: {
            title: "Life Drawing Poses",
            id: 1,
        },
        set: {
            title: "Skinny Male",
            id: 100,
        },
    },
    {
        sketchfabId: "c16e5298d6064ae488312c410eb21c36",
        title: "Skinny male lunge 2",
        category: {
            title: "Life Drawing Poses",
            id: 1,
        },
        set: {
            title: "Skinny Male",
            id: 100,
        },
    },
    {
        sketchfabId: "9cbf2b674c074466a756a0b160d1e41f",
        title: "Skinny male lunge 3",
        category: {
            title: "Life Drawing Poses",
            id: 1,
        },
        set: {
            title: "Skinny Male",
            id: 100,
        },
    },
];
