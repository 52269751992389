import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-568d580c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "LayoutButton__label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon_button = _resolveComponent("ui-icon-button")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setLayout && _ctx.setLayout(...args))),
    id: 'layout-' + _ctx.layout.id,
    class: _normalizeClass(["LayoutButton", { 'LayoutButton--active': _ctx.isActive }])
  }, [
    _createVNode(_component_ui_icon_button, {
      "icon-id": _ctx.layout.iconId
    }, null, 8, ["icon-id"]),
    (_ctx.laptop)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.layout.id), 1))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}