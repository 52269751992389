import Sketchfab from "@sketchfab/viewer-api";
import {
    SketchfabApi,
    ArtboardCamera,
    SketchfabClickEvent,
    Artboard,
    SphericalCoordinates,
} from "@/types";
import { useArtboardStore } from "@/stores/artboards";
import { zeroOffset } from "@/data/camera";
import cameraTools from "./display-settings/camera";
import postprocessingtools from "./display-settings/postprocessing";
import lighting from "./display-settings/lighting";
import { getPositionFromLatLon } from "./general/transform";
import { subtractCameras, sumCameras } from "./general/camera";
import scene from "./display-settings/scene";
import material from "./display-settings/material";
import environment from "./display-settings/environment";
import materials from "@/data/settings/materials";
import postprocessings from "@/data/settings/postprocessing";
import { useUiStore } from "@/stores/ui";
import background from "./display-settings/background";
let cameraMoves = false;

const sketchfabSettings = {
    camera: 0,
    camera_easing: 0.1,
    orbit_rotation_factor: 1.6,
    annotation_tooltip_visible: 0,
    ui_controls: 0,
    ui_infos: 0,
    ui_watermark: 0,
    ui_stop: 0,
    shadow_texture_size: 2048,
    ui_hint: 0,
};

export const updateCamera = (api: SketchfabApi, cameraData: ArtboardCamera) => {
    const position = getPositionFromLatLon(
        cameraData.latlon,
        cameraData.radius,
        cameraData.target
    );
    api.setCameraLookAt(position, [...cameraData.target], 0);
};

export const updateCameraForArtboard = (artboard: Artboard) => {
    const store = useArtboardStore();

    const adjustedCamera = sumCameras(store.general.camera, artboard.offset);
    updateCamera(artboard.api, adjustedCamera);
    // Whenever the camera moves, update the lights to follow the camera
    lighting.rigLightPositions(
        artboard.api,
        {
            lat: artboard.displaySettings.latitude as number,
            lon: artboard.displaySettings.longitude as number,
        },
        store.general.camera.latlon,
        artboard.offset.latlon,
        artboard.displaySettings.environmentRotation as number
    );
};

export const transplantCameraOffest = () => {
    // reset all camera offsets to zero and apply the general camera
    const store = useArtboardStore();
    store.selected.forEach((artboard) => {
        store.artboardSetOffset(artboard.id, zeroOffset);
        updateCameraForArtboard(artboard);
    });
};

export const initViewer = (artboard: Artboard) => {
    const store = useArtboardStore();
    const uiStore = useUiStore();

    const tick = (api: SketchfabApi, id: string) => {
        const isOtherArtboard = (artboard: Artboard) => {
            return (
                !store.artboardForEvents ||
                artboard.id !== store.artboardForEvents.id
            );
        };

        const isActiveArtboard = (artboard: Artboard) => {
            return artboard.api && artboard.loaded;
        };

        cameraTools.captureCamera(api).then((cameraFromEvent) => {
            // todo should we check for id as well or can we always assume the current matches the id?
            if (store.current) {
                const offset = subtractCameras(
                    cameraFromEvent,
                    store.general.camera
                );
                store.artboardSetOffset(id, offset);
                // Whenever the camera moves, update the lights to follow the camera
                lighting.rigLightPositions(
                    api,
                    {
                        lat: artboard.displaySettings.latitude as number,
                        lon: artboard.displaySettings.longitude as number,
                    },
                    store.general.camera.latlon,
                    offset.latlon,
                    artboard.displaySettings.environmentRotation as number
                );
            } else {
                const artboard = store.getArtboard(id);
                if (artboard) {
                    store.general.camera = subtractCameras(
                        cameraFromEvent,
                        artboard.offset
                    );
                    // Whenever the camera moves, update the lights to follow the camera
                    lighting.rigLightPositions(
                        artboard.api,
                        {
                            lat: artboard.displaySettings.latitude as number,
                            lon: artboard.displaySettings.longitude as number,
                        },
                        store.general.camera.latlon,
                        artboard.offset.latlon,
                        artboard.displaySettings.environmentRotation as number
                    );
                }

                for (const ab of store.selected) {
                    if (isOtherArtboard(ab) && isActiveArtboard(ab)) {
                        updateCameraForArtboard(ab);
                    }
                }
            }
        });

        if (cameraMoves) {
            requestAnimationFrame(() => tick(api, id));
        }
    };

    const onViewerReady = (api: SketchfabApi) => {
        const lensAngle = (
            store.getDisplaySettingsValue("lensAngle")
                ? store.getDisplaySettingsValue("lensAngle")
                : 25
        ) as number;
        cameraTools.setFov(api, lensAngle);
        cameraTools.getCameraCenter(api).then((center) => {
            store.setArtboardSceneCenter(artboard.id, center);
        });
        api.setLightFeatureEnabled(true);
        scene.getRootNode(api).then((instanceID) => {
            store.setArtboardRootnodeID(artboard.id, instanceID);
        });
        scene.getNodeMap(api);
        environment.setEnvironment(api, { shadowEnabled: false });
        // default postprocessing
        postprocessingtools.setPostProcessing(api, postprocessings[0].payload);
        background.setBackground(api, { color: [185, 203, 211] });
        lighting.initLights(api);
        const latlon: SphericalCoordinates = {
            lat: store.getDisplaySettingsValue("latitude") as number,
            lon: store.getDisplaySettingsValue("longitude") as number,
        };
        lighting.rigLightPositions(
            api,
            latlon as SphericalCoordinates,
            { lat: 0, lon: 0 },
            { lat: 0, lon: 0 },
            store.getDisplaySettingsValue("environmentRotation") as number
        );
        setTimeout(() => {
            updateCamera(api, store.general.camera);
        }, 100);
    };

    const onClick = (event: SketchfabClickEvent) => {
        store.artboardForEvents = artboard;

        const tryToDeselect = () => {
            // in drawing mode we are in focus layout
            // deselecting an artboard should close the drawing panel
            // deselect the artboard, but since we are in focus layout
            // the selection needs to stay
            if (uiStore.liveDrawingPanel) {
                uiStore.liveDrawingPanel = false;
            } else {
                store.deselectArtboard();
            }
        };

        const selectArtboard = () => {
            store.selectArtboard(artboard.id);
            uiStore.liveDrawingPanel = false;
        };

        event.instanceID === null ? tryToDeselect() : selectArtboard();
    };

    const onCameraStop = () => {
        cameraMoves = false;
    };

    const onCameraStart = (artboard: Artboard) => {
        const api = artboard.api;
        if (api) {
            if (store.current && store.current.id !== artboard.id) {
                store.deselectArtboard();
            }
            if (!cameraMoves) {
                store.artboardForEvents = artboard;
                cameraMoves = true;
                tick(api, artboard.id);
            }
        }
    };

    const storeMaterialList = async (api: SketchfabApi) => {
        return new Promise<void>((resolve) => {
            material.storeMaterialList(api).then(() => {
                const id = store.getDisplaySettingsValue("material")
                    ? store.getDisplaySettingsValue("material")
                    : 1;
                const currentMaterial = materials.find((m) => m.id === id);
                if (currentMaterial) {
                    material.applyMaterialPreset(
                        api,
                        currentMaterial.payload,
                        currentMaterial.shadingtype
                    );
                }
                resolve();
            });
        });
    };

    return new Promise((resolve, reject) => {
        const iframe = document.getElementById("artboard-" + artboard.id);
        const client = new Sketchfab(iframe);

        client.init(artboard.scan.sketchfabId, {
            success: function onSuccess(api: SketchfabApi) {
                api.start();
                api.addEventListener("click", onClick);
                api.addEventListener("camerastart", () =>
                    onCameraStart(artboard)
                );
                api.addEventListener("camerastop", onCameraStop);
                api.addEventListener("viewerready", () => {
                    onViewerReady(api);
                    storeMaterialList(api).then(() => {
                        resolve(api);
                    });
                });
                api.addEventListener("annotationSelect", (index: number) => {
                    console.log(index);
                });
            },
            error: function onError() {
                reject("Viewer error");
            },
            ...sketchfabSettings,
        });
    });
};
