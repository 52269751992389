
import { computed, defineComponent } from "vue";
import Breadcrumb from "./Breadcrumb.vue";
import { useArtboardStore } from "@/stores/artboards";
import { Category, Scan, Set, Breadcrumb as BreadcrumbType } from "@/types";
import useBreakpoint from "@/composables/use-breakpoint";

export default defineComponent({
    name: "Breadcrumbs",
    components: { Breadcrumb },
    setup() {
        const store = useArtboardStore();
        const { mobile } = useBreakpoint();

        const scans = computed<BreadcrumbType>(() => {
            const l = store.current ? 4 : getSharedLevels().length;
            const title: string = mobile.value && l > 2 ? "..." : "3D Scans";
            return {
                item: {
                    title,
                },
                action: () => gotoUrl(""),
            };
        });

        const isTheSame = (level: string) => {
            const first = store.selected[0];
            for (const artboard of store.selected) {
                const a = artboard.scan[level as keyof Scan] as Category | Set;
                const b = first.scan[level as keyof Scan] as Category | Set;
                if (a.id !== b.id) {
                    return false;
                }
            }
            return true;
        };

        const getSharedLevels = () => {
            if (store.selected.length === 0) return [];

            const breadcrumbs: BreadcrumbType[] = [scans.value];
            const levels = ["category", "set"];
            const first = store.selected[0];

            for (const level of levels) {
                const item = first.scan[level as keyof Scan] as Category | Set;
                if (isTheSame(level)) {
                    const params = `${level}_id=${item.id}`;
                    const breadcrumb: BreadcrumbType = {
                        item,
                        action: () => gotoUrl(params),
                    };
                    breadcrumbs.push(breadcrumb);
                } else {
                    break;
                }
            }
            // delete action of last breadcrumb
            if (breadcrumbs[breadcrumbs.length - 1]) {
                breadcrumbs[breadcrumbs.length - 1].action = null;
            }
            return breadcrumbs;
        };

        const breadcrumbs = computed(() => {
            if (store.current) {
                const category: BreadcrumbType = {
                    item: store.current.scan.category,
                    action: () =>
                        gotoUrl(
                            "category_id=" + store.current?.scan.category.id
                        ),
                };

                const set: BreadcrumbType = {
                    item: store.current.scan.set,
                    action: () =>
                        gotoUrl("set_id=" + store.current?.scan.set.id),
                };

                const scan: BreadcrumbType = {
                    item: {
                        title: store.current.scan.title,
                    },
                    action: null,
                };

                return [scans.value, category, set, scan];
            } else {
                return getSharedLevels();
            }
        });

        const gotoUrl = (params: string) => {
            let url = "https://www.bodiesinmotion.photo/3d-scans";

            if (params.length) {
                url += "?" + params + "page=1";
            }
            const w = window.open(url, "_blank");
            if (w) {
                w.focus();
            }
        };

        return {
            breadcrumbs,
        };
    },
});
