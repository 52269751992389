
import { defineComponent, watch } from "vue";
import useTool from "@/composables/use-tool";
import { durationOptions } from "@/data/drawing-timer";
import UiIconButton from "@/components/ui/icon-button/IconButton.vue";

export default defineComponent({
    name: "UiCheckbox",
    components: { UiIconButton },
    props: {
        title: {
            type: String,
            required: true,
        },
        value: {
            type: Boolean,
            required: true,
        },
        vertical: {
            type: Boolean,
            required: false,
            default: false,
        },
        smallLabel: {
            type: Boolean,
            required: false,
            default: false,
        },
        alternativeIconSet: {
            type: Object,
            required: false,
        },
    },
    setup(props, { emit }) {
        const { localValue, isMixed, atSelectMixed } = useTool(
            props,
            emit,
            null
        );

        return {
            localValue,
            isMixed,
            atSelectMixed,
        };
    },
});
