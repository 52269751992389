
import { defineComponent } from "vue";

export default defineComponent({
    name: "UiButton",
    components: {},
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: "",
        },
        variant: {
            type: String,
            default: "primary",
        },
    },
    setup() {
        return {};
    },
});
