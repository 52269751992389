
import { defineComponent } from "vue";
import UiIconButton from "@/components/ui/icon-button/IconButton.vue";

export default defineComponent({
    name: "DrawingTimerClose",
    components: { UiIconButton },
    setup(props, { emit }) {
        return {
            close: () => emit("close"),
        };
    },
});
