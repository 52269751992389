
import { useArtboardStore } from "@/stores/artboards";
import { Artboard } from "@/types";
import { PropType, defineComponent, ref, computed } from "vue";
import ArtboardHeaderTools from "@/components/artboard-area/artboard/ArtboardHeaderTools.vue";
import ArtboardFooterTools from "@/components/artboard-area/artboard/ArtboardFooterTools.vue";
import ArtboardLoadingScreen from "@/components/artboard-area/artboard/ArtboardLoadingScreen.vue";
import { config } from "@/data/config";
import { useDrawingTimerStore } from "@/stores/drawing-timer";

export default defineComponent({
    name: "Artboard",
    components: {
        ArtboardHeaderTools,
        ArtboardFooterTools,
        ArtboardLoadingScreen,
    },
    props: {
        artboard: {
            type: Object as PropType<Artboard>,
            required: true,
        },
        isCurrent: {
            type: Boolean,
            required: true,
        },
        hasFocus: {
            type: Boolean,
            required: true,
        },
        isEvent: {
            type: Boolean,
            required: true,
        },
    },
    setup(props) {
        const store = useArtboardStore();
        const drawingTimerStore = useDrawingTimerStore();
        const element = ref<HTMLDivElement | null>(null);

        const testMode = ref(false);
        const disableSketchfab = config.disableSketchfab;

        // we need this since there is no :nth of selector in css
        const inactiveIndex = computed(() => {
            const focusedArtboard = store.current
                ? store.current
                : store.previousCurrent;

            const inactiveArtboards = store.selected.filter(
                (a: Artboard) => a !== focusedArtboard
            );
            return inactiveArtboards.indexOf(props.artboard);
        });

        const select = () => {
            store.selectArtboard(props.artboard.id);
        };

        const grayedOut = computed(() => {
            return drawingTimerStore.isPlaying && !props.isCurrent;
        });

        return {
            element,
            testMode,
            disableSketchfab,
            inactiveIndex,
            select,
            grayedOut,
        };
    },
});
