
import { defineComponent, watch, ref } from "vue";

export default defineComponent({
    name: "UiSelect",
    components: {},
    props: {
        label: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        value: {
            type: [String, Number],
            required: true,
        },
        vertical: {
            type: Boolean,
            required: false,
            default: false,
        },
        smallLabel: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props, { emit }) {
        const localValue = ref(props.value);

        watch(
            () => localValue.value,
            () => emit("update", localValue.value)
        );

        watch(
            () => props.value,
            () => {
                localValue.value = props.value;
            }
        );
        return {
            localValue,
        };
    },
});
