import { initViewer, updateCameraForArtboard } from "@/tools/sketchfab/main";
import {
    Artboard,
    SketchfabApi,
    Scan,
    DisplaySettings,
    ArtboardCamera,
    Load,
} from "@/types";
import { useArtboardStore } from "@/stores/artboards";
import {
    setSettings,
    getBackingValues,
} from "@/tools/sketchfab/display-settings";
import { config } from "@/data/config";

export const addArtboard = (
    scan: Scan,
    displaySettings: DisplaySettings,
    offset: ArtboardCamera,
    replace: boolean
) => {
    const store = useArtboardStore();

    const updateDisplaySettings = (artboard: Artboard) => {
        for (const key of store.displaySettingsKeys) {
            const value = artboard.displaySettings[key];
            const backingValues = getBackingValues(
                key,
                artboard.displaySettings
            );
            setSettings(
                artboard,
                key,
                value,
                backingValues,
                store.camera as ArtboardCamera,
                true
            );
        }
    };

    const artboard: Artboard = {
        id: Math.random().toString(36).substring(7),
        scan,
        api: null,
        loaded: false,
        offset,
        displaySettings,
        rootnodeID: null,
        center: [0, 0, 0],
    };

    replace ? store.replaceArtboard(artboard) : store.addArtboard(artboard);

    if (config.disableSketchfab) {
        store.artboardSetLoaded(artboard.id);
    } else {
        setTimeout(() => {
            initViewer(artboard)
                .then((api: SketchfabApi) => {
                    store.artboardAddApi(artboard.id, api);
                    updateDisplaySettings(artboard);
                    updateCameraForArtboard(artboard);
                    store.artboardSetLoaded(artboard.id);
                })
                .catch((error: string) => {
                    console.log(error);
                });
        });
    }
};

export const exportCollection = () => {
    const store = useArtboardStore();
    const load: Load = {
        general: {
            layout: store.general.layout,
            camera: store.general.camera,
        },
        artboards: store.selected,
    };
    return JSON.parse(JSON.stringify(load));
};
