
import { defineComponent } from "vue";

export default defineComponent({
    name: "UiSidePanel",
    props: {
        active: {
            type: Boolean,
            required: true,
        },
        panelId: {
            type: String,
            required: false,
        },
        direction: {
            type: String,
            required: false,
            default: "left",
        },
        size: {
            type: String,
            required: false,
            default: "400",
        },
    },
    setup(props, { emit }) {
        const close = () => emit("close");
        return {
            close,
        };
    },
});
