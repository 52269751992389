import { Material } from "@/types";

const materials: Material[] = [
    {
        id: 1,
        name: "Beige",
        image: "",
        shadingtype: "lit",
        payload: {
            // DiffusePBR: { enable: false },
            SpecularPBR: { factor: 0.3 },
            DiffusePBR: { factor: 0.8, color: [255, 232, 186] },
            AlbedoPBR: { factor: 0.8, color: [255, 232, 186] },
            // MetalnessPBR: { enable: false },
            RoughnessPBR: { enable: true, factor: 0.65 },
            ClearCoat: {
                enable: true,
                factor: 0.35,
                thickness: 2,
                reflectivity: 0,
                tint: [245, 245, 245],
            },
            ClearCoatRoughness: { factor: 0.35 },
        },
    },
    {
        id: 2,
        name: "Dark Glossy",
        image: "",
        shadingtype: "lit",
        payload: {
            // DiffusePBR: { enable: false },
            SpecularPBR: { factor: 0.3 },
            DiffusePBR: { factor: 0.05, color: [255, 255, 255] },
            AlbedoPBR: { factor: 0.1, color: [255, 255, 255] },
            // MetalnessPBR: { enable: true, factor: 0.0 },
            RoughnessPBR: { enable: true, factor: 0.3 },
            SpecularF0: { factor: 0.4 },
            ClearCoat: {
                enable: true,
                factor: 0.35,
                thickness: 2,
                reflectivity: 0,
                tint: [245, 245, 245],
            },
            ClearCoatRoughness: { factor: 0.35 },
        },
    },
    {
        id: 3,
        name: "Matcap",
        image: "",
        shadingtype: "matcap",
        payload: {},
    },
];

export default materials;
