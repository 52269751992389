import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ArtboardAreaTools" }
const _hoisted_2 = { class: "ArtboardAreaTools__tools" }
const _hoisted_3 = { class: "ArtboardAreaTools__global-tools" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_artboard_area_label = _resolveComponent("artboard-area-label")!
  const _component_ui_icon_button = _resolveComponent("ui-icon-button")!
  const _component_layout_picker = _resolveComponent("layout-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.deselectArtboard && _ctx.deselectArtboard(...args))),
      class: "ArtboardAreaTools__deselect"
    }, [
      _createElementVNode("div", null, [
        (_ctx.wideArtboardHeader)
          ? (_openBlock(), _createBlock(_component_artboard_area_label, { key: 0 }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.store.current)
          ? (_openBlock(), _createBlock(_component_ui_icon_button, {
              key: 0,
              id: "display-settings-button",
              onClick: _ctx.openDisplaySettings,
              "icon-id": "settings",
              "has-deviation": _ctx.hasOffset
            }, null, 8, ["onClick", "has-deviation"]))
          : _createCommentVNode("", true),
        (!_ctx.store.current && _ctx.globalHasOffset && !_ctx.isFullscreen)
          ? (_openBlock(), _createBlock(_component_ui_icon_button, {
              key: 1,
              id: "reset-camera-button",
              onClick: _ctx.reset,
              "icon-id": "reset"
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", null, [
        (_ctx.laptop && !_ctx.wideArtboardHeader)
          ? (_openBlock(), _createBlock(_component_artboard_area_label, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", null, [
        (_ctx.store.selected.length > 1)
          ? (_openBlock(), _createBlock(_component_layout_picker, { key: 0 }))
          : _createCommentVNode("", true),
        (!_ctx.isFullscreen && _ctx.laptop)
          ? (_openBlock(), _createBlock(_component_ui_icon_button, {
              key: 1,
              onClick: _ctx.enter,
              "icon-id": "fullscreen"
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.isFullscreen)
          ? (_openBlock(), _createBlock(_component_ui_icon_button, {
              key: 2,
              onClick: _ctx.exit,
              "icon-id": "close"
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}