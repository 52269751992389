import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e50e6e3"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-vertical"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_drawing_timer_camera_path = _resolveComponent("drawing-timer-camera-path")!
  const _component_drawing_timer_density = _resolveComponent("drawing-timer-density")!
  const _component_drawing_timer_camera_order = _resolveComponent("drawing-timer-camera-order")!

  return (_openBlock(), _createElementBlock("div", {
    class: "DrawingTimerTools",
    "data-vertical": _ctx.vertical
  }, [
    _createVNode(_component_drawing_timer_camera_path, {
      vertical: !_ctx.vertical
    }, null, 8, ["vertical"]),
    _createVNode(_component_drawing_timer_density, {
      vertical: !_ctx.vertical
    }, null, 8, ["vertical"]),
    _createVNode(_component_drawing_timer_camera_order, {
      vertical: !_ctx.vertical
    }, null, 8, ["vertical"])
  ], 8, _hoisted_1))
}