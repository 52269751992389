import { defineStore } from "pinia";
import {
    ArtboardAreaLayout,
    Artboard,
    SketchfabApi,
    ArtboardCamera,
    DisplaySettings,
    DisplaySettingsValue,
    MixedState,
    DisplaySettingsKey,
    Coordinate,
} from "@/types";
import { initialCamera } from "@/data/camera";
import { mixedState } from "@/data/settings/settings";
import { initialDisplaySettings } from "@/data/settings/settings";
import { setSettings } from "@/tools/sketchfab/display-settings";
import { isEqual } from "@/tools/object";
import { getBackingValues } from "@/tools/sketchfab/display-settings";
import { orthoLensAngle } from "@/data/constants";

interface ArtboardState {
    general: {
        layout: ArtboardAreaLayout;
        camera: ArtboardCamera;
    };
    selected: Artboard[];
    current: Artboard | null;
    previousCurrent: Artboard | null;
    artboardForEvents: Artboard | null;
    camera: ArtboardCamera | null;
}

export const useArtboardStore = defineStore("artboard", {
    state: () => {
        return {
            general: {
                layout: "grid",
                camera: initialCamera,
            },
            selected: [],
            current: null,
            previousCurrent: null,
            artboardForEvents: null,
            camera: null,
        } as ArtboardState;
    },
    getters: {
        displaySettingsKeys(): DisplaySettingsKey[] {
            return Object.keys(initialDisplaySettings) as DisplaySettingsKey[];
        },
        // artboards
        isLoading(): boolean {
            for (const artboard of this.selected) {
                if (!artboard.loaded) {
                    return true;
                }
            }
            return false;
        },
        artboardWithFocus(): Artboard | null {
            if (this.general.layout === "focus") {
                return this.current ? this.current : this.previousCurrent;
            } else {
                return this.current;
            }
        },
    },
    actions: {
        // display settings
        getGeneralDisplaySettings(): DisplaySettings {
            const displaySettings = {} as DisplaySettings;
            for (const key of this.displaySettingsKeys) {
                const value = this.getSharedDisplaySettingsValue(key);
                displaySettings[key] =
                    value === mixedState || value === null
                        ? initialDisplaySettings[key]
                        : value;
            }
            return displaySettings;
        },
        getSharedDisplaySettingsValue(
            key: keyof DisplaySettings
        ): DisplaySettingsValue {
            let value: DisplaySettingsValue | MixedState | null = null;
            for (const artboard of this.selected) {
                let artboardValue;
                if (key === "lensAngleOrtho") {
                    artboardValue =
                        artboard.displaySettings.lensAngle === orthoLensAngle;
                } else {
                    artboardValue = artboard.displaySettings[key];
                }
                if (value !== null && !isEqual(value, artboardValue)) {
                    return mixedState;
                }
                value = artboardValue;
            }
            return value;
        },
        getDisplaySettingsValue(
            key: keyof DisplaySettings
        ): DisplaySettingsValue {
            if (this.current) {
                if (key === "lensAngleOrtho") {
                    return (
                        this.current.displaySettings.lensAngle ===
                        orthoLensAngle
                    );
                } else {
                    return this.current.displaySettings[key];
                }
            } else {
                return this.getSharedDisplaySettingsValue(key);
            }
        },
        setDisplaySettingsValue(
            key: DisplaySettingsKey,
            value: DisplaySettingsValue
        ) {
            if (this.current) {
                this.current.displaySettings[key] = value;
                if (this.current.api) {
                    const backingValues = getBackingValues(
                        key,
                        this.current.displaySettings
                    );
                    setSettings(
                        this.current,
                        key,
                        value,
                        backingValues,
                        this.general.camera,
                        false
                    );
                }
            } else {
                for (const artboard of this.selected) {
                    artboard.displaySettings[key] = value;
                    const backingValues = getBackingValues(
                        key,
                        artboard.displaySettings
                    );
                    if (artboard.api) {
                        setSettings(
                            artboard,
                            key,
                            value,
                            backingValues,
                            this.general.camera,
                            false
                        );
                    }
                }
            }
        },
        resetDisplaySettings(keys: DisplaySettingsKey[]) {
            if (this.current) {
                for (const key of keys) {
                    const value = initialDisplaySettings[key];
                    this.current.displaySettings[key] = value;
                    if (this.current.api) {
                        const backingValues = getBackingValues(
                            key,
                            this.current.displaySettings
                        );
                        setSettings(
                            this.current,
                            key,
                            value,
                            backingValues,
                            this.general.camera,
                            false
                        );
                    }
                }
            } else {
                for (const artboard of this.selected) {
                    for (const key of keys) {
                        const value = initialDisplaySettings[key];
                        artboard.displaySettings[key] = value;
                        if (artboard.api) {
                            const backingValues = getBackingValues(
                                key,
                                artboard.displaySettings
                            );
                            setSettings(
                                artboard,
                                key,
                                value,
                                backingValues,
                                this.general.camera,
                                false
                            );
                        }
                    }
                }
            }
        },
        // artboards
        getArtboard(id: string) {
            return this.selected.find((a) => a.id === id);
        },
        addArtboard(artboard: Artboard) {
            this.selected.push(artboard);
        },
        replaceArtboard(artboard: Artboard) {
            if (this.current) {
                const index = this.selected.indexOf(this.current);
                if (index > -1) {
                    this.selected[index] = artboard;
                }
            }
        },
        artboardAddApi(id: string, api: SketchfabApi) {
            const artboard = this.getArtboard(id);
            if (artboard) {
                artboard.api = api;
            }
        },
        artboardSetLoaded(id: string) {
            const artboard = this.getArtboard(id);
            if (artboard) {
                artboard.loaded = true;
            }
        },
        artboardSetOffset(id: string, offset: ArtboardCamera) {
            const artboard = this.getArtboard(id);
            if (artboard) {
                artboard.offset = offset;
            }
        },
        setArtboardRootnodeID(id: string, rootnodeID: number) {
            const artboard = this.getArtboard(id);
            if (artboard) {
                artboard.rootnodeID = rootnodeID;
            }
        },
        setArtboardSceneCenter(id: string, center: Coordinate) {
            const artboard = this.getArtboard(id);
            if (artboard) {
                artboard.center = center;
            }
        },
        setLayout(layout: ArtboardAreaLayout) {
            if (this.general.layout === "focus" && layout !== "focus") {
                // always deselect when leaving focus mode
                this.current = null;
            }
            this.general.layout = layout;
        },
        selectArtboard(id: string) {
            const artboard = this.getArtboard(id);
            if (artboard) {
                if (this.general.layout === "focus") {
                    if (artboard !== this.artboardWithFocus) {
                        // this is a change of focus
                        // we only want to have it focus, not the current state
                        this.current = null;
                        this.previousCurrent = artboard;
                    } else {
                        if (this.current !== artboard) {
                            // a select while focussed
                            this.current = artboard;
                            this.previousCurrent = null;
                        }
                    }
                } else {
                    this.current = artboard;
                }
            }
        },
        deselectArtboard() {
            if (this.current) {
                this.previousCurrent = this.current;
            }
            this.current = null;
        },
        removeArtboard(artboard: Artboard) {
            const index = this.selected.indexOf(artboard);
            if (this.current === artboard) {
                this.current = null;
            }
            if (index > -1) {
                this.selected.splice(index, 1);
            }
        },
    },
});
