
import { computed, defineComponent, ref } from "vue";
import { useArtboardStore } from "@/stores/artboards";
import DisplaySettingsSection from "@/components/settings/DisplaySettingsSection.vue";
import { sections } from "@/data/sections";
import UiIconButton from "@/components/ui/icon-button/IconButton.vue";
import { useUiStore } from "@/stores/ui";
import useBreakpoint from "@/composables/use-breakpoint";

export default defineComponent({
    name: "DisplaySettings",
    components: {
        UiIconButton,
        DisplaySettingsSection,
    },
    setup() {
        const store = useArtboardStore();
        const uiStore = useUiStore();
        const { laptop } = useBreakpoint();

        const m = ref([0]);

        const isLoading = computed(() => store.isLoading);

        const theSections = sections;

        const settingsTitle = computed(() => {
            if (store.current) {
                const artboardIndex = store.selected.findIndex(
                    (artboard) => artboard.id === store.current?.id
                );
                return `Model ${artboardIndex + 1} Overrides`;
            } else {
                return "Global settings";
            }
        });

        const close = () => {
            uiStore.displaySettingsPanel = false;
        };

        return {
            m,
            isLoading,
            theSections,
            settingsTitle,
            close,
            laptop,
        };
    },
});
