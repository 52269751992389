import { SketchfabApi, SphericalCoordinates } from "@/types/artboard-area";
import {
    getMatrixFromLatLon,
    sumSphericalCoordinates,
} from "../general/transform";
import environment from "./environment";

const setShadow = (api: SketchfabApi, shadowEnabled: boolean) => {
    api.setLight(0, { shadowEnabled });
};

const rigLightIntensities = (api: SketchfabApi, intensity: number) => {
    api.setLight(0, { intensity: intensity * 1 });
};
/**
 * The three lights are supposed to work as a rig. With one set of options
 * we can adjust the settings for the other two lights.
 * Light 0 is the key light
 * Light 1 is the fill light
 * Light 2 is the rim light
 * @param api
 * @param latlonLights The latlon of the lights in the UI
 * @param latlonCameraGeneral The latlon of the camera
 * @param latlonCameraOffset The latlon of the camera offset
 */
const rigLightPositions = (
    api: SketchfabApi,
    latlonLights: SphericalCoordinates,
    latlonCameraGeneral: SphericalCoordinates,
    latlonCameraOffset: SphericalCoordinates,
    environmentRotation: number
) => {
    const sumCoord = sumSphericalCoordinates([
        latlonCameraGeneral,
        latlonCameraOffset,
        latlonLights,
    ]);

    // Key light
    const matrixKey = getMatrixFromLatLon(
        {
            lat: latlonLights.lat,
            lon: sumCoord.lon + 180,
        },
        2,
        [0, 0, 0]
    );
    api.setLight(0, { matrix: matrixKey });

    /**
     * Fill and Rim lights disalbed for now
     * 2023-10-23
     */
    api.setLight(1, { intensity: 0 });
    api.setLight(2, { intensity: 0 });

    // const matrixFill = getMatrixFromLatLon(
    //     {
    //         lat: sumCoord.lat - 0,
    //         lon: sumCoord.lon + 60,
    //     },
    //     2,
    //     [0, 0, 0]
    // );
    // api.setLight(1, { matrix: matrixFill, color: [1, 1, 1] });

    // // The rim light is on the opposite side of the main light
    // const matrixRim = getMatrixFromLatLon(
    //     {
    //         lat: -sumCoord.lat + 35,
    //         lon: sumCoord.lon - 150,
    //     },
    //     2,
    //     [0, 0, 0]
    // );
    // api.setLight(2, { matrix: matrixRim, color: [1, 1, 1] });

    environment.setEnvironment(
        api,
        {
            rotation: environmentRotation,
        },
        latlonCameraGeneral,
        latlonCameraOffset
    );
};

const initLights = (api: SketchfabApi) => {
    api.setLight(0, { type: "DIRECTION", enabled: true, color: [1, 1, 1] });
    api.setLight(1, { enabled: false });
    api.setLight(2, { enabled: false });
};

export default {
    initLights,
    setShadow,
    rigLightPositions,
    rigLightIntensities,
};
