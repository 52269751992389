import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2162158c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "DrawingTimer" }
const _hoisted_2 = { class: "DrawingTimer__set-1" }
const _hoisted_3 = { class: "DrawingTimer__duration-slider" }
const _hoisted_4 = { class: "DrawingTimer__set-2" }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon_button = _resolveComponent("ui-icon-button")!
  const _component_drawing_timer_duration_slider = _resolveComponent("drawing-timer-duration-slider")!
  const _component_drawing_timer_tools_popup = _resolveComponent("drawing-timer-tools-popup")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_drawing_timer_close = _resolveComponent("drawing-timer-close")!
  const _component_drawing_timer_frame_control = _resolveComponent("drawing-timer-frame-control")!
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_drawing_timer_tools = _resolveComponent("drawing-timer-tools")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.store.isPlaying)
        ? (_openBlock(), _createBlock(_component_ui_icon_button, {
            key: 0,
            id: "pause-button",
            onClick: _ctx.stopTimer,
            "icon-id": "pause",
            border: ""
          }, null, 8, ["onClick"]))
        : (_openBlock(), _createBlock(_component_ui_icon_button, {
            key: 1,
            id: "play-button",
            onClick: _ctx.startTimer,
            "icon-id": "play",
            border: ""
          }, null, 8, ["onClick"])),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_drawing_timer_duration_slider)
      ]),
      (_ctx.mobile)
        ? (_openBlock(), _createBlock(_component_v_dialog, { key: 2 }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_ui_icon_button, _mergeProps(props, { "icon-id": "video_settings" }), null, 16)
            ]),
            default: _withCtx(({ isActive }) => [
              _createVNode(_component_drawing_timer_tools_popup, {
                onClose: ($event: any) => (isActive.value = false)
              }, null, 8, ["onClose"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.mobile)
        ? (_openBlock(), _createBlock(_component_drawing_timer_close, {
            key: 3,
            onClose: _ctx.close
          }, null, 8, ["onClose"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_drawing_timer_frame_control, { onResetTimer: _ctx.startTimer }, null, 8, ["onResetTimer"]),
      (_ctx.mobile)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_ui_button, {
              variant: "modest",
              label: "Update",
              onClick: _ctx.updateLayout
            }, null, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.mobile)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["DrawingTimer__set-3", [
                _ctx.isSpherical && _ctx.semiWideDrawingTimer
                    ? 'SphericalWideDrawingTimer'
                    : '',
            ]])
        }, [
          _createVNode(_component_drawing_timer_tools),
          _createVNode(_component_ui_button, {
            label: "Update Layout",
            onClick: _ctx.updateLayout
          }, null, 8, ["onClick"])
        ], 2))
      : _createCommentVNode("", true),
    (!_ctx.mobile)
      ? (_openBlock(), _createBlock(_component_drawing_timer_close, {
          key: 1,
          onClose: _ctx.close
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ]))
}