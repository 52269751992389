import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02d016c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ArtboardTools" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon_button = _resolveComponent("ui-icon-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_ui_icon_button, {
        onClick: _ctx.openDisplaySettings,
        "icon-id": "settings",
        "has-deviation": _ctx.hasOffset
      }, null, 8, ["onClick", "has-deviation"]),
      (!_ctx.isFullscreen)
        ? (_openBlock(), _createBlock(_component_ui_icon_button, {
            key: 0,
            onClick: _ctx.overrideCameraOffset,
            "icon-id": "reset-navigation",
            "has-deviation": _ctx.hasOffset
          }, null, 8, ["onClick", "has-deviation"]))
        : _createCommentVNode("", true),
      _createVNode(_component_ui_icon_button, {
        class: "drawing-timer-button",
        onClick: _ctx.openLiveDrawing,
        "icon-id": "play"
      }, null, 8, ["onClick"])
    ]),
    _createElementVNode("div", null, [
      (!_ctx.isFullscreen)
        ? (_openBlock(), _createBlock(_component_ui_icon_button, {
            key: 0,
            onClick: _ctx.remove,
            class: "remove-artboard",
            "icon-id": "delete_outline"
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ])
  ]))
}