import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-094caa5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ResetButton" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon_button = _resolveComponent("ui-icon-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ui_icon_button, {
      "icon-id": "refresh",
      label: "Reset to default",
      disabled: _ctx.disabled
    }, null, 8, ["disabled"])
  ]))
}