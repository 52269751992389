
import { defineComponent, PropType } from "vue";
import { Artboard } from "@/types";
import { useArtboardStore } from "@/stores/artboards";
import { sumCameras } from "@/tools/sketchfab/general/camera";
import { transplantCameraOffest } from "@/tools/sketchfab/main";
import UiIconButton from "@/components/ui/icon-button/IconButton.vue";
import { useUiStore } from "@/stores/ui";
import useReset from "@/composables/use-reset";
import { allTools } from "@/data/sections";

export default defineComponent({
    name: "ArtboardHeaderTools",
    components: { UiIconButton },
    props: {
        artboard: {
            type: Object as PropType<Artboard>,
            required: true,
        },
        element: {
            type: Object as PropType<HTMLDivElement>,
            required: true,
        },
    },
    setup(props) {
        const store = useArtboardStore();
        const uiStore = useUiStore();

        const remove = () => store.removeArtboard(props.artboard);

        const { hasOffset } = useReset(allTools);

        const openDisplaySettings = () => {
            uiStore.displaySettingsPanel = true;
            uiStore.liveDrawingPanel = false;
        };

        const overrideCameraOffset = () => {
            // Take the offset from the current artboard
            // and apply it to the general camera
            console.log("store.current?.offset", store.current?.offset);
            console.log("store.general.camera", store.general.camera);
            if (store.current?.offset) {
                store.general.camera = sumCameras(
                    store.general.camera,
                    store.current.offset
                );

                // store.artboardSetOffset(props.artboard.id, zeroOffset);
                transplantCameraOffest();
            }
        };

        const openLiveDrawing = () => {
            uiStore.liveDrawingPanel = true;
            uiStore.displaySettingsPanel = false;
            store.setLayout("focus");
        };
        return {
            remove,
            openDisplaySettings,
            openLiveDrawing,
            overrideCameraOffset,
            hasOffset,
        };
    },
});
