import { useDrawingTimerStore } from "@/stores/drawing-timer";

export default function useTimer(tick: () => void) {
    const store = useDrawingTimerStore();
    let timerMain = 0;
    const timeRemainingInterval = 100;
    let timerForTimeRemaining = 0;
    store.timeToNextFrame = store.duration;

    const start = () => {
        stop();
        timerMain = setInterval(() => {
            tick();
            store.timeToNextFrame = store.duration;
        }, store.duration);
        timerForTimeRemaining = setInterval(() => {
            store.timeToNextFrame -= timeRemainingInterval;
        }, timeRemainingInterval);
        store.isPlaying = true;
    };

    const stop = () => {
        clearInterval(timerMain);
        clearInterval(timerForTimeRemaining);
        store.isPlaying = false;
        store.timeToNextFrame = store.duration;
    };

    return {
        start,
        stop,
    };
}
