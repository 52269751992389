import { SketchfabApi, SphericalCoordinates } from "@/types/artboard-area";
import { EnvironmentSettings } from "@/types/sketchfab-types";
import { toRadians, sumSphericalCoordinates } from "../general/transform";

const getEnvironment = (api: SketchfabApi) => {
    api.getEnvironment((err: Error, settings: EnvironmentSettings) => {
        console.log(settings);
    });
};

const setEnvironment = (
    api: SketchfabApi,
    settings: EnvironmentSettings,
    latlonCameraGeneral?: SphericalCoordinates,
    latlonCameraOffset?: SphericalCoordinates
) => {
    if (
        settings.rotation !== undefined &&
        latlonCameraGeneral &&
        latlonCameraOffset
    ) {
        // Environment rotation is an offset from the camera rotation
        const sumEnvironment = sumSphericalCoordinates([
            latlonCameraGeneral,
            latlonCameraOffset,
            {
                lat: 0,
                lon: settings.rotation,
            },
        ]);
        settings.rotation = toRadians(-sumEnvironment.lon);
    }
    api.setEnvironment({ uid: settings.uid }, () => {
        api.setEnvironment(settings);
    });
};

export default {
    setEnvironment,
    getEnvironment,
};
