import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0259858d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "LayoutPicker" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_layout_button = _resolveComponent("layout-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layouts, (layout, index) => {
      return (_openBlock(), _createBlock(_component_layout_button, {
        key: index,
        layout: layout,
        "is-active": _ctx.store.general.layout === layout.id,
        onSetLayout: _ctx.setLayout
      }, null, 8, ["layout", "is-active", "onSetLayout"]))
    }), 128))
  ]))
}