
import { defineComponent } from "vue";
import UiIconButton from "@/components/ui/icon-button/IconButton.vue";
import useBreakpoint from "@/composables/use-breakpoint";

export default defineComponent({
    name: "LayoutButton",
    components: {
        UiIconButton,
    },
    props: {
        layout: {
            type: Object,
            required: true,
        },
        isActive: {
            type: Boolean,
            required: true,
        },
    },
    setup(props, { emit }) {
        const { laptop } = useBreakpoint();

        return {
            setLayout: () => emit("set-layout", props.layout.id),
            laptop,
        };
    },
});
