import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "DrawingTimerCameraOrder",
  "data-key": "cameraOrder"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_select = _resolveComponent("ui-select")!

  return (_ctx.path === 'Radial')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ui_select, {
          options: _ctx.options,
          label: "Order",
          value: _ctx.store.cameraOrder,
          onUpdate: _ctx.update,
          vertical: _ctx.vertical,
          "small-label": ""
        }, null, 8, ["options", "value", "onUpdate", "vertical"])
      ]))
    : _createCommentVNode("", true)
}