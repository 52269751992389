import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53b64cd0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "ArtboardAreaLabel",
    "aria-disabled": _ctx.isLoading
  }, _toDisplayString(_ctx.label), 9, _hoisted_1))
}