import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", null, _toDisplayString(_ctx.title), 1),
    _createVNode(_component_v_select, {
      items: _ctx.allOptions,
      modelValue: _ctx.localValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
      "item-title": _ctx.labelKey,
      "item-value": "id"
    }, null, 8, ["items", "modelValue", "item-title"])
  ]))
}