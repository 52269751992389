
import { computed, defineComponent } from "vue";
import UiIconButton from "@/components/ui/icon-button/IconButton.vue";
import useBreakpoint from "@/composables/use-breakpoint";

export default defineComponent({
    name: "Help",
    components: { UiIconButton },
    setup() {
        const { laptop } = useBreakpoint();

        const text = computed(() => {
            const laptopText = "<p>Helptext for big screen</p>";
            const mobileText = "<p>Helptext for small screen</p>";
            return laptop.value ? laptopText : mobileText;
        });

        return {
            text,
        };
    },
});
