import {
    DisplaySettingsKey,
    DisplaySettingsValue,
    Coordinate,
    Artboard,
    SphericalCoordinates,
    BackingValues,
    DisplaySettings,
    ArtboardCamera,
} from "@/types";
import lighting from "./display-settings/lighting";
import camera from "./display-settings/camera";
import scene from "./display-settings/scene";
import environment from "./display-settings/environment";
import material from "./display-settings/material";
import materials from "@/data/settings/materials";
import environments from "@/data/settings/environments";

const getBackingKeys = (key: keyof DisplaySettings): DisplaySettingsKey[] => {
    switch (key) {
        case "longitude":
            return ["latitude"];
        case "latitude":
            return ["longitude"];
        case "rotationX":
            return ["rotationY", "rotationZ"];
        case "rotationY":
            return ["rotationX", "rotationZ"];
        case "rotationZ":
            return ["rotationX", "rotationY"];
        case "environment":
            return [
                "environmentRotation",
                "environmentIntensity",
                "shadowEnabled",
                "lightIntensity",
            ];
        case "shadowEnabled":
            return ["lightIntensity"];
        default:
            return [];
    }
};

export const getBackingValues = (
    key: DisplaySettingsKey,
    source: DisplaySettings
): BackingValues => {
    const backingKeys = getBackingKeys(key);
    const backingValues = {} as BackingValues;
    for (const backingKey of backingKeys) {
        backingValues[backingKey] = source[backingKey];
    }
    return backingValues;
};

export const setSettings = (
    artboard: Artboard,
    key: DisplaySettingsKey,
    value: DisplaySettingsValue,
    backingValues: BackingValues,
    generalCamera: ArtboardCamera,
    onLoad: boolean
) => {
    switch (key) {
        case "intensity":
            lighting.rigLightIntensities(artboard.api, value as number);
            break;
        case "latitude":
            {
                if (!generalCamera) return;
                const latlonOffset: SphericalCoordinates = {
                    lat: value as number,
                    lon: backingValues.longitude as number,
                };
                lighting.rigLightPositions(
                    artboard.api,
                    latlonOffset as SphericalCoordinates,
                    generalCamera.latlon,
                    artboard.offset.latlon,
                    backingValues.environmentRotation as number
                );
            }
            break;
        case "longitude":
            {
                if (!generalCamera) return;
                const latlonOffset: SphericalCoordinates = {
                    lat: backingValues.latitude as number,
                    lon: value as number,
                };
                lighting.rigLightPositions(
                    artboard.api,
                    latlonOffset as SphericalCoordinates,
                    generalCamera.latlon,
                    artboard.offset.latlon,
                    backingValues.environmentRotation as number
                );
            }
            break;
        case "shadows":
            if (typeof value === "boolean")
                lighting.setShadow(artboard.api, value);
            break;
        case "material":
            const mtl = materials.find((m) => m.id === value);
            if (mtl) {
                material.applyMaterialPreset(
                    artboard.api,
                    mtl.payload,
                    mtl.shadingtype
                );
            }
            break;
        case "lensAngle":
            camera.setLensAngle(artboard.api, value as number, !onLoad);
            break;
        case "rotationX":
            {
                const rotation: Coordinate = [
                    value as number,
                    backingValues.rotationY as number,
                    backingValues.rotationZ as number,
                ];
                scene.setMatrixFromRotation(
                    artboard.api,
                    artboard.rootnodeID,
                    rotation
                );
            }
            break;
        case "rotationY":
            {
                const rotation: Coordinate = [
                    backingValues.rotationX as number,
                    value as number,
                    backingValues.rotationZ as number,
                ];
                scene.setMatrixFromRotation(
                    artboard.api,
                    artboard.rootnodeID,
                    rotation
                );
            }
            break;
        case "rotationZ":
            {
                const rotation: Coordinate = [
                    backingValues.rotationX as number,
                    backingValues.rotationY as number,
                    value as number,
                ];
                scene.setMatrixFromRotation(
                    artboard.api,
                    artboard.rootnodeID,
                    rotation
                );
            }
            break;
        case "environment":
            // todo @klaas
            const env = environments.find((m) => m.id === value);
            if (env && generalCamera) {
                environment.setEnvironment(
                    artboard.api,
                    {
                        uid: env.payload.uid,
                        rotation: backingValues.environmentRotation as number,
                        lightIntensity: backingValues.lightIntensity as number,
                        shadowEnabled: backingValues.shadowEnabled as boolean,
                        exposure: backingValues.environmentIntensity as number,
                    },
                    generalCamera.latlon,
                    artboard.offset.latlon
                );
            }
            break;
        case "environmentRotation":
            {
                if (!generalCamera) return;
                environment.setEnvironment(
                    artboard.api,
                    {
                        rotation: value as number,
                    },
                    generalCamera.latlon,
                    artboard.offset.latlon
                );
            }
            break;
        case "environmentIntensity":
            environment.setEnvironment(artboard.api, {
                exposure: value as number,
            });
            break;
        case "lightIntensity":
            environment.setEnvironment(artboard.api, {
                lightIntensity: value as number,
            });
            break;
        case "shadowEnabled":
            environment.setEnvironment(artboard.api, {
                shadowEnabled: value as boolean,
                lightIntensity: backingValues.lightIntensity as number,
            });
            break;
        case "displayBackground":
            // todo @klaas
            break;
        case "boundingBox":
            if (typeof value === "boolean") {
                scene.setObjectVisibility(artboard.api, ["guides"], value);
            }
            break;
        case "groundPlane":
            if (typeof value === "boolean") {
                scene.setObjectVisibility(artboard.api, ["ground"], value);
            }
            break;
        default:
            // do nothing
            break;
    }
};
