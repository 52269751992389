import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ArtboardArea" }
const _hoisted_2 = ["data-layout", "data-n"]
const _hoisted_3 = { class: "ArtboardArea__main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_artboard_area_tools = _resolveComponent("artboard-area-tools")!
  const _component_artboard = _resolveComponent("artboard")!
  const _component_ui_icon_button = _resolveComponent("ui-icon-button")!
  const _component_display_settings_drawing_aid_toggle = _resolveComponent("display-settings-drawing-aid-toggle")!
  const _component_display_settings_light_longitude = _resolveComponent("display-settings-light-longitude")!
  const _component_display_settings_light_latitude = _resolveComponent("display-settings-light-latitude")!
  const _component_horizontal_vertical = _resolveComponent("horizontal-vertical")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_horizontal_vertical, null, {
      main: _withCtx(() => [
        _createElementVNode("div", {
          class: "ArtboardArea__content",
          "data-layout": _ctx.store.general.layout,
          "data-n": _ctx.store.selected.length
        }, [
          _createVNode(_component_artboard_area_tools),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "ArtboardArea__background",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.deselectArtboard && _ctx.deselectArtboard(...args)))
            }),
            _createVNode(_TransitionGroup, { name: "artboardarea" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.selected, (artboard) => {
                  return (_openBlock(), _createBlock(_component_artboard, {
                    key: artboard.id,
                    artboard: artboard,
                    "has-focus": _ctx.hasFocus(artboard),
                    "is-current": _ctx.store.current === artboard,
                    "is-event": _ctx.store.artboardForEvents === artboard
                  }, null, 8, ["artboard", "has-focus", "is-current", "is-event"]))
                }), 128))
              ]),
              _: 1
            })
          ])
        ], 8, _hoisted_2)
      ]),
      "bottom-left": _withCtx(() => [
        (
                        _ctx.store.selected.length > 0 && !_ctx.uiStore.liveDrawingPanel
                    )
          ? (_openBlock(), _createBlock(_component_ui_icon_button, {
              key: 0,
              class: _normalizeClass({ DrawingTimer_fullscreen: _ctx.fullscreen }),
              id: "open-drawing-timer",
              label: "Drawing Timer",
              "icon-id": "play",
              variant: "black",
              onClick: _ctx.openDrawingTimer
            }, null, 8, ["class", "onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_display_settings_drawing_aid_toggle)
      ]),
      "bottom-right": _withCtx(() => [
        _createVNode(_component_display_settings_light_longitude, { class: "longitude-slider" })
      ]),
      vertical: _withCtx(() => [
        _createVNode(_component_display_settings_light_latitude, { class: "latitude-slider" })
      ]),
      corner: _withCtx(() => [
        _createVNode(_component_ui_icon_button, {
          id: "open-drawing-timer",
          "icon-id": "light",
          size: "big"
        })
      ]),
      _: 1
    })
  ]))
}