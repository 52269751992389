import { PostMessage } from "@/types";
import { exportCollection } from "@/tools/artboard";

const sendMessage = (message: PostMessage) => {
    if (
        message.action === "save-to-collection" ||
        message.action === "favorite"
    ) {
        message.data = exportCollection();
    }
    console.group("3D app sends message");
    console.log(message.action);
    if (message.data) console.log(message.data);
    window.parent.postMessage(message, "*");
    console.groupEnd();
};

export default {
    sendMessage,
};
