import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["data-mobile", "data-wide-drawing-timer"]
const _hoisted_2 = { class: "App__main" }
const _hoisted_3 = ["data-fullscreen", "data-mobile"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_display_settings = _resolveComponent("display-settings")!
  const _component_drawing_timer = _resolveComponent("drawing-timer")!
  const _component_breadcrumbs = _resolveComponent("breadcrumbs")!
  const _component_artboard_area = _resolveComponent("artboard-area")!
  const _component_drawing_timer_countdown = _resolveComponent("drawing-timer-countdown")!
  const _component_toolbar = _resolveComponent("toolbar")!
  const _component_ui_side_panel = _resolveComponent("ui-side-panel")!
  const _component_ui_bottom_panel = _resolveComponent("ui-bottom-panel")!

  return (_openBlock(), _createElementBlock("div", {
    id: "full-screen-node",
    class: "App",
    "data-mobile": _ctx.mobile,
    "data-wide-drawing-timer": _ctx.wideDrawingTimer
  }, [
    _createVNode(_component_ui_side_panel, {
      active: 
                _ctx.uiStore.displaySettingsPanel ||
                (_ctx.mobile && _ctx.uiStore.liveDrawingPanel)
            ,
      "panel-id": "regular-settings-panel",
      direction: _ctx.mobile ? 'bottom' : 'left',
      size: _ctx.uiStore.liveDrawingPanel ? '300' : '400'
    }, {
      panel: _withCtx(() => [
        (_ctx.mobile)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _withDirectives(_createVNode(_component_display_settings, null, null, 512), [
                [_vShow, _ctx.uiStore.displaySettingsPanel]
              ]),
              _createElementVNode("div", null, [
                _withDirectives(_createVNode(_component_drawing_timer, null, null, 512), [
                  [_vShow, _ctx.uiStore.liveDrawingPanel]
                ])
              ])
            ], 64))
          : (_openBlock(), _createBlock(_component_display_settings, { key: 1 }))
      ]),
      main: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "App__top",
            "data-fullscreen": _ctx.fullscreen,
            "data-mobile": _ctx.mobile
          }, [
            (_ctx.showBreadcrumbs)
              ? (_openBlock(), _createBlock(_component_breadcrumbs, { key: 0 }))
              : _createCommentVNode("", true),
            _createVNode(_component_artboard_area),
            (
                                _ctx.uiStore.liveDrawingPanel &&
                                _ctx.drawingTimerStore.duration > 10 * 1000
                            )
              ? (_openBlock(), _createBlock(_component_drawing_timer_countdown, { key: 1 }))
              : _createCommentVNode("", true)
          ], 8, _hoisted_3),
          (_ctx.showToolbar)
            ? (_openBlock(), _createBlock(_component_toolbar, { key: 0 }))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["active", "direction", "size"]),
    (!_ctx.mobile)
      ? (_openBlock(), _createBlock(_component_ui_bottom_panel, {
          key: 0,
          active: _ctx.uiStore.liveDrawingPanel
        }, {
          default: _withCtx(() => [
            _createVNode(_component_drawing_timer)
          ]),
          _: 1
        }, 8, ["active"]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}