
import { defineComponent } from "vue";
import useBreakpoint from "@/composables/use-breakpoint";

export default defineComponent({
    name: "HorizontalVertical",
    setup() {
        const { laptop } = useBreakpoint();

        return {
            laptop,
        };
    },
});
