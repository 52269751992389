
import { defineComponent } from "vue";
import UiIconButton from "@/components/ui/icon-button/IconButton.vue";

export default defineComponent({
    name: "ResetButton",
    components: { UiIconButton },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
});
