
import { computed, defineComponent } from "vue";
import { useDrawingTimerStore } from "@/stores/drawing-timer";

export default defineComponent({
    name: "DrawingTimerCountdown",
    components: {},
    setup() {
        const store = useDrawingTimerStore();

        const formatted = computed(() => {
            // store.timeToNextFrame is in ms
            const seconds = Math.ceil(store.timeToNextFrame / 1000);

            const addZero = (num: number) => {
                if (num < 10) {
                    return `0${num}`;
                } else {
                    return num;
                }
            };
            if (seconds > 60) {
                const minutes = Math.floor(seconds / 60);
                const remainingSeconds = addZero(seconds % 60);
                return `${minutes}:${remainingSeconds}`;
            } else {
                return `:${addZero(seconds)}`;
            }
        });

        return {
            formatted,
        };
    },
});
