
import { defineComponent } from "vue";
import { useArtboardStore } from "@/stores/artboards";
import { ArtboardAreaLayout } from "@/types";
import LayoutButton from "@/components/artboard-area/layout/LayoutButton.vue";

export default defineComponent({
    name: "LayoutPicker",
    components: {
        LayoutButton,
    },
    setup() {
        const store = useArtboardStore();

        const setLayout = (style: ArtboardAreaLayout) => {
            store.setLayout(style);
            if (style === "focus" && store.selected.length > 0) {
                store.previousCurrent = store.current
                    ? store.current
                    : store.selected[0];
                store.current = null;
            }
        };

        const layouts = [
            {
                id: "grid",
                iconId: "grid",
            },
            {
                id: "row",
                iconId: "row",
            },
            {
                id: "focus",
                iconId: "focus",
            },
        ];

        return {
            setLayout,
            layouts,
            store,
        };
    },
});
