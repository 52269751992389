
import { computed, defineComponent } from "vue";
import { useArtboardStore } from "@/stores/artboards";

export default defineComponent({
    name: "ArtboardAreaLabel",
    setup() {
        const store = useArtboardStore();

        const label = computed(() => {
            if (store.selected.length === 0) {
                return "no artboards";
            }
            if (store.selected.length === 1) {
                return store.selected[0].scan.title;
            } else {
                return store.current
                    ? store.current.scan.title
                    : "scan group set";
            }
        });
        const isLoading = computed(() => store.isLoading);

        return {
            label,
            isLoading,
        };
    },
});
