import { computed } from "vue";
import { Tool } from "@/types";
import { useArtboardStore } from "@/stores/artboards";
import { initialDisplaySettings } from "@/data/settings/settings";
import { isEqual } from "@/tools/object";

export default function useReset(tools: Tool[]) {
    const store = useArtboardStore();

    const keys = computed(() => {
        const activeTools = tools.filter(
            (tool: Tool) => !store.current || tool.forIndividualArtboard
        );
        return activeTools.map((tool: Tool) => tool.key);
    });

    const reset = () => {
        store.resetDisplaySettings(keys.value);
    };

    const hasOffset = computed(() => {
        for (const key of keys.value) {
            const initialValue = initialDisplaySettings[key];
            const thisValue = store.getDisplaySettingsValue(key);

            if (!isEqual(initialValue, thisValue)) {
                return true;
            }
        }
        return false;
    });

    const resetDisabled = computed(() => !hasOffset.value);

    return {
        hasOffset,
        reset,
        resetDisabled,
    };
}
