import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55f52cc3"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-checked", "data-vertical", "data-small-label"]
const _hoisted_2 = { class: "Checkbox__mixed" }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_ui_icon_button = _resolveComponent("ui-icon-button")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!

  return (_openBlock(), _createElementBlock("div", {
    class: "Checkbox",
    "data-checked": _ctx.localValue,
    "data-vertical": _ctx.vertical,
    "data-small-label": _ctx.smallLabel
  }, [
    _createElementVNode("label", null, _toDisplayString(_ctx.title), 1),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      (!_ctx.alternativeIconSet)
        ? (_openBlock(), _createBlock(_component_v_icon, {
            key: 0,
            onClick: _ctx.atSelectMixed,
            icon: "fas fa-square-minus"
          }, null, 8, ["onClick"]))
        : (_openBlock(), _createBlock(_component_ui_icon_button, {
            key: 1,
            id: "alternative-checkbox",
            onClick: _ctx.atSelectMixed,
            "icon-id": _ctx.alternativeIconSet.mixed,
            size: "big"
          }, null, 8, ["onClick", "icon-id"]))
    ], 512), [
      [_vShow, _ctx.isMixed]
    ]),
    _withDirectives(_createVNode(_component_v_checkbox, null, {
      input: _withCtx(() => [
        (!_ctx.alternativeIconSet)
          ? (_openBlock(), _createBlock(_component_v_icon, {
              key: 0,
              modelValue: _ctx.localValue,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.localValue = !_ctx.localValue)),
              icon: 
                        _ctx.localValue
                            ? 'fas fa-check-square'
                            : 'fa-regular fa-square'
                    
            }, null, 8, ["modelValue", "icon"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_ui_icon_button, {
                id: "alternative-checkbox",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.localValue = !_ctx.localValue)),
                "icon-id": 
                            _ctx.localValue
                                ? _ctx.alternativeIconSet.checked
                                : _ctx.alternativeIconSet.unchecked
                        ,
                size: "big"
              }, null, 8, ["icon-id"])
            ]))
      ]),
      _: 1
    }, 512), [
      [_vShow, !_ctx.isMixed]
    ])
  ], 8, _hoisted_1))
}