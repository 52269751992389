import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69ab8a42"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-title"]
const _hoisted_2 = { class: "ExpansionTitle__icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon_button = _resolveComponent("ui-icon-button")!
  const _component_ui_deviation = _resolveComponent("ui-deviation")!

  return (_openBlock(), _createElementBlock("div", {
    class: "ExpansionTitle",
    "data-title": _ctx.title
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ui_icon_button, {
        "icon-id": _ctx.icon,
        size: "big"
      }, null, 8, ["icon-id"]),
      (_ctx.hasOffset)
        ? (_openBlock(), _createBlock(_component_ui_deviation, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
  ], 8, _hoisted_1))
}