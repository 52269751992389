import { Coordinate, SphericalCoordinates } from "@/types/artboard-area";
import { glMatrix, mat4, quat, vec3 } from "gl-matrix";

const quatToSpherical = function (quat: quat): { lat: number; lon: number } {
    // Convert a quat to lat lon values
    const look = vec3.fromValues(0, 0, 1);
    vec3.transformQuat(look, look, quat);
    vec3.normalize(look, look);
    if (Math.abs(look[0]) < 0.001) look[0] = 0.001;

    const angles = { lat: 0, lon: 0 };
    const radius = 1;

    angles.lat = Math.acos(look[2] / radius);
    angles.lon = Math.atan2(look[1], look[0]);

    return angles;
};

export const getLatLonFromPos = function (
    sf_pos: Coordinate,
    sf_target: Coordinate
) {
    const pos = vec3.fromValues(-sf_pos[1], sf_pos[0], -sf_pos[2]);
    const target = vec3.fromValues(-sf_target[1], sf_target[0], -sf_target[2]);
    const ray = vec3.create();
    vec3.subtract(ray, pos, target);

    const up = vec3.fromValues(0, 0, 1);
    // const front = vec3.fromValues(1, 0, 0)
    const matrix = mat4.create();
    mat4.targetTo(matrix, pos, target, up);

    const q = quat.create();
    mat4.getRotation(q, matrix);
    const angles = quatToSpherical(q);

    const degAngles = {
        lat: (angles.lat * 180) / Math.PI - 90,
        lon: (angles.lon * 180) / Math.PI + 180,
    };
    return degAngles;
};

const rotateVectorFromLatLon = (
    latlon: SphericalCoordinates,
    radius: number,
    target: number[]
) => {
    const lat = glMatrix.toRadian(latlon.lat);
    const lon = glMatrix.toRadian(latlon.lon);
    const R = radius || -1.5;
    const origin = vec3.create();
    const T = target
        ? vec3.fromValues(target[0], target[1], target[2])
        : vec3.create();
    const greenwich = vec3.fromValues(0, R, 0);
    vec3.rotateX(greenwich, greenwich, origin, lat);
    vec3.rotateZ(greenwich, greenwich, origin, lon);
    return [greenwich, T];
};

/*
Convert a lat and lon value to a matrix. This assumes a certain radius
and an up vector. We're also assuming the target is at the origin
*/
export const getPositionFromLatLon = function (
    latlon: SphericalCoordinates,
    radius: number,
    target: number[]
): Coordinate {
    const [greenwich, T] = rotateVectorFromLatLon(latlon, radius, target);
    vec3.add(greenwich, greenwich, T);

    return [greenwich[0], greenwich[1], greenwich[2]];
};

export const getPositionFromVector = (
    position: number[],
    radius: number,
    target: number[]
) => {
    const pos = vec3.create();
    vec3.multiply(
        pos,
        vec3.fromValues(position[0], position[1], position[2]),
        vec3.fromValues(radius, radius, radius)
    );
    vec3.add(pos, pos, vec3.fromValues(target[0], target[1], target[2]));
    return [pos[0], pos[1], pos[2]] as Coordinate;
};
/*
Convert a lat and lon value to a matrix. This assumes a certain radius
and an up vector. We're also assuming the target is at the origin
*/
export const getMatrixFromLatLon = function (
    latlon: SphericalCoordinates,
    radius: number,
    target: number[]
) {
    const [greenwich, T] = rotateVectorFromLatLon(latlon, radius, target);
    const up = vec3.fromValues(0, 0, 1);
    const matrix = mat4.create();
    mat4.targetTo(matrix, greenwich, T, up);

    return sfMatrixFromGlMatrix(matrix);
};

export const getMatrixFromRotation = (rotation: Coordinate) => {
    const matrix = mat4.create();
    mat4.rotateX(matrix, matrix, toRadians(rotation[0]));
    mat4.rotateY(matrix, matrix, toRadians(rotation[1]));
    mat4.rotateZ(matrix, matrix, toRadians(rotation[2]));
    return sfMatrixFromGlMatrix(matrix);
};

export const getRadius = function (sf_pos: Coordinate, sf_target: Coordinate) {
    const pos = vec3.fromValues(-sf_pos[1], sf_pos[0], -sf_pos[2]);
    const target = vec3.fromValues(-sf_target[1], sf_target[0], -sf_target[2]);
    return vec3.distance(pos, target);
};

export const toRadians = (angle: number) => {
    return angle * (Math.PI / 180);
};

const matrixFromSfMatrix = (sfMatrix: number[]): mat4 => {
    return mat4.fromValues(
        sfMatrix[0],
        sfMatrix[1],
        sfMatrix[2],
        sfMatrix[3],
        sfMatrix[4],
        sfMatrix[5],
        sfMatrix[6],
        sfMatrix[7],
        sfMatrix[8],
        sfMatrix[9],
        sfMatrix[10],
        sfMatrix[11],
        sfMatrix[12],
        sfMatrix[13],
        sfMatrix[14],
        sfMatrix[15]
    );
};

const sfMatrixFromGlMatrix = (m4: mat4): number[] => {
    const sfMatrix = [];
    sfMatrix[0] = m4[0];
    sfMatrix[1] = m4[1];
    sfMatrix[2] = m4[2];
    sfMatrix[3] = m4[3];

    sfMatrix[4] = m4[4];
    sfMatrix[5] = m4[5];
    sfMatrix[6] = m4[6];
    sfMatrix[7] = m4[7];

    sfMatrix[8] = m4[8];
    sfMatrix[9] = m4[9];
    sfMatrix[10] = m4[10];
    sfMatrix[11] = m4[11];

    sfMatrix[12] = m4[12];
    sfMatrix[13] = m4[13];
    sfMatrix[14] = m4[14];
    sfMatrix[15] = m4[15];
    return sfMatrix;
};

export const sumSphericalCoordinates = (coords: SphericalCoordinates[]) => {
    let lat = 0;
    let lon = 0;
    coords.forEach((coord) => {
        lat += coord.lat;
        lon += coord.lon;
    });
    return { lat, lon };
};
