
import { defineComponent, ref, watch, computed } from "vue";
import { useDrawingTimerStore } from "@/stores/drawing-timer";
import { durationOptions, initialDurationIndex } from "@/data/drawing-timer";

export default defineComponent({
    name: "DrawingTimerDurationSlider",
    components: {},
    setup() {
        const store = useDrawingTimerStore();
        const durationIndex = ref(initialDurationIndex);

        const durationLabel = computed(() => {
            return durationOptions[durationIndex.value].label;
        });

        watch(
            () => durationIndex.value,
            () => {
                store.duration = durationOptions[durationIndex.value].duration;
            }
        );

        return {
            durationIndex,
            durationOptions,
            durationLabel,
        };
    },
});
