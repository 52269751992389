import {
    CameraOrder,
    CameraPath,
    CameraPathDensity,
    CameraVectors,
} from "@/types";

export const cameraPathDensityOptions: CameraPathDensity[] = [
    "Sparse",
    "Regular",
    "Dense",
];

export const radialCameraVectors: CameraVectors = {
    "3": [
        [0.0, 1.0, 0.0],
        [0.866, -0.5, 0.0],
        [-0.866, -0.5, 0.0],
    ],
    "4": [
        [0.0, 1.0, 0.0],
        [1.0, -0.0, 0.0],
        [-0.0, -1.0, 0.0],
        [-1.0, 0.0, 0.0],
    ],
    "5": [
        [0.0, 1.0, 0.0],
        [0.951, 0.309, 0.0],
        [0.588, -0.809, 0.0],
        [-0.588, -0.809, 0.0],
        [-0.951, 0.309, 0.0],
    ],
    "6": [
        [0.0, 1.0, 0.0],
        [0.866, 0.5, 0.0],
        [0.866, -0.5, 0.0],
        [-0.0, -1.0, 0.0],
        [-0.866, -0.5, 0.0],
        [-0.866, 0.5, 0.0],
    ],
    "7": [
        [0.0, 1.0, 0.0],
        [0.782, 0.623, 0.0],
        [0.975, -0.223, 0.0],
        [0.434, -0.901, 0.0],
        [-0.434, -0.901, 0.0],
        [-0.975, -0.223, 0.0],
        [-0.782, 0.623, 0.0],
    ],
    "8": [
        [0.0, 1.0, 0.0],
        [0.707, 0.707, 0.0],
        [1.0, -0.0, 0.0],
        [0.707, -0.707, 0.0],
        [-0.0, -1.0, 0.0],
        [-0.707, -0.707, 0.0],
        [-1.0, 0.0, 0.0],
        [-0.707, 0.707, 0.0],
    ],
    "9": [
        [0.0, 1.0, 0.0],
        [0.643, 0.766, 0.0],
        [0.985, 0.174, 0.0],
        [0.866, -0.5, 0.0],
        [0.342, -0.94, 0.0],
        [-0.342, -0.94, 0.0],
        [-0.866, -0.5, 0.0],
        [-0.985, 0.174, 0.0],
        [-0.643, 0.766, 0.0],
    ],
    "10": [
        [0.0, 1.0, 0.0],
        [0.588, 0.809, 0.0],
        [0.951, 0.309, 0.0],
        [0.951, -0.309, 0.0],
        [0.588, -0.809, 0.0],
        [-0.0, -1.0, 0.0],
        [-0.588, -0.809, 0.0],
        [-0.951, -0.309, 0.0],
        [-0.951, 0.309, 0.0],
        [-0.588, 0.809, 0.0],
    ],
    "11": [
        [0.0, 1.0, 0.0],
        [0.541, 0.841, 0.0],
        [0.91, 0.415, 0.0],
        [0.99, -0.142, 0.0],
        [0.756, -0.655, 0.0],
        [0.282, -0.959, 0.0],
        [-0.282, -0.959, 0.0],
        [-0.756, -0.655, 0.0],
        [-0.99, -0.142, 0.0],
        [-0.91, 0.415, 0.0],
        [-0.541, 0.841, 0.0],
    ],
    "12": [
        [0.0, 1.0, 0.0],
        [0.5, 0.866, 0.0],
        [0.866, 0.5, 0.0],
        [1.0, -0.0, 0.0],
        [0.866, -0.5, 0.0],
        [0.5, -0.866, 0.0],
        [-0.0, -1.0, 0.0],
        [-0.5, -0.866, 0.0],
        [-0.866, -0.5, 0.0],
        [-1.0, 0.0, 0.0],
        [-0.866, 0.5, 0.0],
        [-0.5, 0.866, 0.0],
    ],
    "13": [
        [0.0, 1.0, 0.0],
        [0.465, 0.885, 0.0],
        [0.823, 0.568, 0.0],
        [0.993, 0.121, 0.0],
        [0.935, -0.355, 0.0],
        [0.663, -0.749, 0.0],
        [0.239, -0.971, 0.0],
        [-0.239, -0.971, 0.0],
        [-0.663, -0.749, 0.0],
        [-0.935, -0.355, 0.0],
        [-0.993, 0.121, 0.0],
        [-0.823, 0.568, 0.0],
        [-0.465, 0.885, 0.0],
    ],
    "14": [
        [0.0, 1.0, 0.0],
        [0.434, 0.901, 0.0],
        [0.782, 0.623, 0.0],
        [0.975, 0.223, 0.0],
        [0.975, -0.223, 0.0],
        [0.782, -0.623, 0.0],
        [0.434, -0.901, 0.0],
        [-0.0, -1.0, 0.0],
        [-0.434, -0.901, 0.0],
        [-0.782, -0.623, 0.0],
        [-0.975, -0.223, 0.0],
        [-0.975, 0.223, 0.0],
        [-0.782, 0.623, 0.0],
        [-0.434, 0.901, 0.0],
    ],
};
export const sphericalCameraVectors: CameraVectors = {
    "3": [
        [-0.145, -0.455, -0.142],
        [0.349, 0.069, -0.348],
        [0.226, 0.349, 0.274],
    ],
    "4": [
        [-0.142, -0.455, -0.144],
        [0.348, 0.077, -0.347],
        [-0.421, 0.266, -0.003],
        [0.254, 0.272, 0.33],
    ],
    "5": [
        [-0.157, -0.121, -0.456],
        [-0.062, -0.446, 0.211],
        [0.453, -0.077, -0.192],
        [-0.454, 0.191, 0.076],
        [0.229, 0.227, 0.379],
    ],
    "6": [
        [-0.165, -0.17, -0.437],
        [-0.369, -0.177, 0.283],
        [0.225, -0.422, 0.139],
        [0.369, 0.181, -0.28],
        [-0.322, 0.375, -0.06],
        [0.22, 0.286, 0.344],
    ],
    "7": [
        [-0.108, -0.112, -0.473],
        [-0.378, -0.258, 0.196],
        [0.198, -0.454, -0.055],
        [0.341, -0.098, 0.349],
        [0.306, 0.248, -0.304],
        [-0.378, 0.321, -0.044],
        [0.025, 0.342, 0.361],
    ],
    "8": [
        [-0.247, 0.065, -0.428],
        [-0.276, -0.41, 0.062],
        [0.219, -0.412, -0.173],
        [0.405, -0.133, 0.257],
        [0.253, 0.255, -0.344],
        [-0.398, 0.296, 0.041],
        [0.126, 0.47, 0.107],
        [-0.104, -0.138, 0.467],
    ],
    "9": [
        [-0.117, -0.275, -0.399],
        [-0.453, -0.207, 0.024],
        [0.231, -0.44, 0.032],
        [0.496, -0.044, -0.007],
        [0.308, 0.265, -0.287],
        [-0.44, 0.234, -0.008],
        [-0.106, 0.412, -0.259],
        [0.133, 0.212, 0.43],
        [-0.092, -0.225, 0.435],
    ],
    "10": [
        [-0.055, 0.09, -0.487],
        [-0.176, -0.341, -0.317],
        [-0.472, -0.124, 0.095],
        [0.194, -0.457, -0.03],
        [0.488, -0.097, 0.017],
        [0.331, 0.252, -0.272],
        [-0.377, 0.323, -0.032],
        [0.039, 0.476, 0.139],
        [0.173, 0.111, 0.453],
        [-0.136, -0.237, 0.417],
    ],
    "11": [
        [-0.026, 0.106, -0.485],
        [-0.178, -0.302, -0.353],
        [-0.174, 0.43, -0.181],
        [-0.438, -0.237, 0.013],
        [0.212, -0.443, -0.077],
        [0.422, -0.091, 0.249],
        [0.422, 0.042, -0.26],
        [-0.438, 0.203, 0.122],
        [0.271, 0.407, 0.096],
        [-0.019, 0.193, 0.458],
        [-0.047, -0.315, 0.382],
    ],
    "12": [
        [-0.032, 0.188, 0.46],
        [0.375, -0.103, 0.31],
        [-0.094, -0.319, 0.37],
        [-0.413, 0.2, 0.193],
        [0.307, 0.373, 0.118],
        [0.207, -0.452, -0.031],
        [-0.403, -0.292, 0.021],
        [-0.086, 0.48, -0.106],
        [0.46, -0.019, -0.191],
        [-0.072, -0.318, -0.376],
        [-0.373, 0.102, -0.313],
        [0.122, 0.156, -0.456],
    ],
    "13": [
        [0.173, 0.234, -0.403],
        [-0.273, 0.037, -0.414],
        [0.089, -0.296, -0.39],
        [-0.166, 0.455, -0.118],
        [-0.297, -0.394, -0.067],
        [0.162, -0.466, 0.069],
        [0.392, -0.129, 0.281],
        [0.466, -0.053, -0.167],
        [-0.498, 0.012, -0.001],
        [-0.295, 0.267, 0.299],
        [0.3, 0.391, 0.068],
        [0.114, 0.176, 0.452],
        [-0.169, -0.241, 0.401],
    ],
    "14": [
        [0.319, -0.214, -0.318],
        [0.198, 0.275, -0.366],
        [-0.166, 0.055, -0.466],
        [-0.145, -0.358, -0.313],
        [-0.221, 0.43, -0.123],
        [-0.364, -0.321, 0.109],
        [0.116, -0.482, 0.054],
        [0.389, -0.19, 0.247],
        [0.476, 0.142, -0.024],
        [-0.483, 0.042, -0.111],
        [-0.322, 0.21, 0.317],
        [0.152, 0.462, 0.108],
        [0.149, 0.175, 0.441],
        [-0.085, -0.222, 0.438],
    ],
    "15": [
        [0.274, -0.223, -0.351],
        [0.161, 0.307, -0.357],
        [-0.127, 0.046, -0.48],
        [-0.174, -0.362, -0.295],
        [-0.257, 0.412, -0.109],
        [-0.354, -0.312, 0.159],
        [0.058, -0.492, 0.052],
        [0.398, -0.269, 0.13],
        [0.47, 0.124, -0.108],
        [-0.463, 0, -0.184],
        [-0.411, 0.127, 0.253],
        [0.157, 0.466, 0.075],
        [0.343, 0.112, 0.343],
        [-0.067, 0.268, 0.414],
        [-0.009, -0.207, 0.453],
    ],
    "16": [
        [0.148, -0.221, -0.422],
        [0.439, -0.189, -0.139],
        [0.163, 0.264, -0.389],
        [-0.208, 0.067, -0.448],
        [-0.258, -0.342, -0.253],
        [-0.231, 0.418, -0.142],
        [-0.358, -0.289, 0.191],
        [0.05, -0.495, 0.002],
        [0.31, -0.289, 0.261],
        [0.43, 0.233, -0.091],
        [-0.485, 0.029, -0.107],
        [-0.37, 0.203, 0.265],
        [0.1, 0.478, 0.096],
        [0.373, 0.116, 0.308],
        [-0.008, 0.226, 0.445],
        [-0.058, -0.202, 0.451],
    ],
    "17": [
        [0.125, -0.425, -0.226],
        [0.421, -0.119, -0.237],
        [0.121, 0.337, -0.346],
        [-0.27, 0.135, -0.395],
        [0.071, -0.079, -0.486],
        [-0.287, -0.322, -0.248],
        [-0.256, 0.424, -0.046],
        [-0.36, -0.267, 0.215],
        [-0.013, -0.473, 0.153],
        [0.374, -0.283, 0.166],
        [0.438, 0.224, -0.072],
        [-0.493, 0.028, -0.07],
        [-0.355, 0.16, 0.31],
        [0.133, 0.471, 0.088],
        [0.371, 0.094, 0.319],
        [0.0, 0.273, 0.416],
        [0.004, -0.161, 0.471],
    ],
    "18": [
        [0.084, -0.434, -0.228],
        [0.409, -0.166, -0.229],
        [0.21, 0.294, -0.341],
        [-0.246, 0.137, -0.41],
        [0.093, -0.081, -0.482],
        [-0.29, -0.272, -0.301],
        [-0.149, 0.439, -0.18],
        [-0.332, -0.36, 0.092],
        [0.045, -0.472, 0.154],
        [0.393, -0.256, 0.167],
        [0.461, 0.186, -0.039],
        [-0.489, 0.057, -0.072],
        [-0.306, 0.356, 0.17],
        [-0.361, -0.047, 0.339],
        [0.158, 0.459, 0.112],
        [0.34, 0.106, 0.347],
        [-0.034, 0.228, 0.441],
        [0.02, -0.203, 0.454],
    ],
    "19": [
        [0.208, -0.4, -0.21],
        [0.43, -0.081, -0.238],
        [0.207, 0.264, -0.369],
        [-0.245, 0.104, -0.42],
        [0.066, -0.113, -0.481],
        [-0.192, -0.374, -0.267],
        [-0.075, 0.451, -0.197],
        [-0.463, -0.135, -0.126],
        [-0.284, -0.375, 0.163],
        [0.088, -0.471, 0.135],
        [0.417, -0.218, 0.162],
        [0.437, 0.237, -0.019],
        [-0.429, 0.241, -0.073],
        [-0.23, 0.387, 0.211],
        [-0.393, -0.013, 0.306],
        [0.161, 0.448, 0.145],
        [0.327, 0.104, 0.361],
        [-0.047, 0.169, 0.467],
        [0.024, -0.221, 0.445],
    ],
    "20": [
        [0.147, 0.339, -0.333],
        [0.094, -0.419, -0.253],
        [0.442, -0.217, -0.075],
        [0.334, -0.012, -0.369],
        [-0.253, 0.144, -0.404],
        [0.015, -0.098, -0.488],
        [-0.296, -0.269, -0.297],
        [-0.172, 0.446, -0.139],
        [-0.468, -0.169, 0.018],
        [-0.211, -0.439, 0.1],
        [0.172, -0.449, 0.13],
        [0.362, -0.146, 0.308],
        [0.448, 0.21, -0.055],
        [-0.462, 0.175, -0.067],
        [-0.255, 0.348, 0.248],
        [-0.353, -0.038, 0.35],
        [0.133, 0.468, 0.107],
        [0.335, 0.207, 0.305],
        [0.008, 0.159, 0.472],
        [-0.022, -0.244, 0.433],
    ],
    "21": [
        [0.211, 0.409, -0.189],
        [0.148, -0.442, -0.176],
        [0.367, -0.128, -0.31],
        [0.127, 0.177, -0.448],
        [-0.278, 0.091, -0.403],
        [0.003, -0.181, -0.464],
        [-0.23, -0.361, -0.253],
        [-0.152, 0.433, -0.192],
        [-0.471, -0.129, -0.097],
        [-0.277, -0.395, 0.124],
        [0.099, -0.453, 0.184],
        [0.241, -0.157, 0.407],
        [0.459, 0.168, -0.094],
        [-0.438, 0.23, -0.052],
        [-0.228, 0.379, 0.228],
        [-0.404, 0.009, 0.291],
        [0.44, -0.221, 0.071],
        [0.141, 0.447, 0.168],
        [0.379, 0.168, 0.275],
        [-0.005, 0.171, 0.467],
        [-0.136, -0.206, 0.432],
    ],
    "22": [
        [0.188, 0.302, -0.348],
        [0.022, -0.433, -0.244],
        [0.486, -0.003, -0.11],
        [0.272, -0.066, -0.412],
        [-0.16, 0.17, -0.44],
        [-0.066, -0.154, -0.468],
        [-0.357, -0.231, -0.259],
        [-0.143, 0.442, -0.178],
        [-0.472, -0.121, 0.102],
        [-0.215, -0.448, 0.036],
        [0.14, -0.427, 0.214],
        [0.413, -0.15, 0.234],
        [0.38, 0.318, -0.05],
        [-0.44, 0.14, -0.184],
        [-0.343, 0.336, 0.134],
        [-0.31, 0.06, 0.384],
        [0.334, -0.351, -0.115],
        [0.08, 0.482, 0.096],
        [0.337, 0.21, 0.3],
        [0.107, -0.073, 0.48],
        [-0.03, 0.295, 0.4],
        [-0.186, -0.28, 0.367],
    ],
    "23": [
        [0.114, 0.346, -0.338],
        [0.19, -0.359, -0.288],
        [0.443, -0.07, -0.215],
        [0.2, 0.045, -0.454],
        [-0.259, 0.099, -0.413],
        [-0.075, -0.17, -0.462],
        [-0.224, -0.38, -0.231],
        [-0.199, 0.405, -0.208],
        [-0.457, -0.151, -0.13],
        [-0.308, -0.348, 0.178],
        [0.002, -0.497, 0.03],
        [0.131, -0.346, 0.333],
        [0.465, -0.009, 0.177],
        [0.399, 0.281, -0.097],
        [-0.454, 0.188, -0.078],
        [-0.258, 0.387, 0.177],
        [-0.423, 0.027, 0.261],
        [0.363, -0.336, 0.054],
        [0.076, 0.49, 0.041],
        [0.266, 0.316, 0.277],
        [0.216, -0.016, 0.448],
        [-0.081, 0.237, 0.431],
        [-0.153, -0.143, 0.451],
    ],
    "24": [
        [0.132, 0.314, -0.364],
        [0.162, -0.366, -0.296],
        [0.436, -0.101, -0.218],
        [0.184, -0.015, -0.464],
        [-0.201, 0.169, -0.423],
        [-0.157, -0.17, -0.44],
        [-0.168, -0.432, -0.185],
        [-0.179, 0.441, -0.144],
        [-0.433, -0.173, -0.176],
        [-0.315, -0.357, 0.144],
        [0.029, -0.484, 0.114],
        [0.22, -0.269, 0.357],
        [0.465, -0.03, 0.178],
        [0.421, 0.226, -0.139],
        [-0.444, 0.16, -0.156],
        [-0.349, 0.318, 0.157],
        [-0.451, -0.035, 0.207],
        [0.35, -0.354, 0.02],
        [0.154, 0.472, -0.036],
        [0.327, 0.295, 0.231],
        [0.166, 0.073, 0.464],
        [-0.192, 0.128, 0.441],
        [-0.022, 0.414, 0.275],
        [-0.132, -0.226, 0.423],
    ],
    "25": [
        [0.191, 0.302, -0.347],
        [0.153, -0.363, -0.305],
        [0.427, -0.07, -0.246],
        [0.16, -0.044, -0.469],
        [-0.13, 0.165, -0.451],
        [-0.203, -0.172, -0.42],
        [-0.161, -0.431, -0.19],
        [-0.153, 0.426, -0.206],
        [-0.448, -0.177, -0.127],
        [-0.27, -0.397, 0.134],
        [0.064, -0.482, 0.106],
        [0.258, -0.267, 0.332],
        [0.471, -0.04, 0.155],
        [0.424, 0.24, -0.103],
        [-0.407, 0.161, -0.237],
        [-0.246, 0.414, 0.124],
        [-0.407, -0.124, 0.258],
        [-0.46, 0.162, 0.101],
        [0.362, -0.341, -0.019],
        [0.141, 0.478, -0.004],
        [0.342, 0.267, 0.244],
        [0.16, 0.044, 0.469],
        [-0.219, 0.128, 0.428],
        [0.022, 0.37, 0.333],
        [-0.089, -0.245, 0.424],
    ],
    "26": [
        [0.157, 0.298, -0.367],
        [0.155, -0.363, -0.304],
        [0.403, -0.092, -0.276],
        [0.145, -0.029, -0.475],
        [-0.18, 0.127, -0.446],
        [-0.18, -0.201, -0.418],
        [-0.138, -0.445, -0.175],
        [-0.17, 0.408, -0.229],
        [-0.437, -0.175, -0.16],
        [-0.298, -0.384, 0.106],
        [0.037, -0.476, 0.141],
        [0.268, -0.253, 0.334],
        [0.49, -0.064, 0.057],
        [0.412, 0.237, -0.146],
        [0.097, 0.486, -0.042],
        [-0.419, 0.167, -0.21],
        [-0.27, 0.403, 0.112],
        [-0.392, -0.145, 0.27],
        [-0.472, 0.115, 0.11],
        [0.327, -0.375, -0.011],
        [0.319, 0.345, 0.165],
        [0.376, 0.087, 0.316],
        [0.094, 0.065, 0.484],
        [-0.243, 0.138, 0.412],
        [0.014, 0.382, 0.32],
        [-0.087, -0.247, 0.423],
    ],
    "27": [
        [-0.024, 0.24, -0.435],
        [0.145, -0.419, -0.225],
        [0.396, -0.193, -0.231],
        [0.272, 0.086, -0.408],
        [-0.258, -0.03, -0.425],
        [0.061, -0.162, -0.467],
        [-0.174, -0.371, -0.282],
        [-0.072, 0.465, -0.161],
        [-0.342, 0.258, -0.254],
        [-0.441, -0.16, -0.167],
        [-0.279, -0.411, 0.031],
        [0.038, -0.486, 0.099],
        [0.206, -0.24, 0.384],
        [0.46, -0.061, 0.181],
        [0.47, 0.11, -0.119],
        [0.256, 0.379, -0.197],
        [-0.485, 0.114, 0.004],
        [-0.279, 0.401, 0.101],
        [-0.413, -0.163, 0.226],
        [-0.345, 0.141, 0.33],
        [0.346, -0.351, 0.073],
        [0.105, 0.466, 0.138],
        [0.374, 0.289, 0.157],
        [0.237, 0.122, 0.42],
        [-0.088, -0.024, 0.49],
        [-0.047, 0.318, 0.38],
        [-0.133, -0.324, 0.354],
    ],
    "28": [
        [-0.043, 0.213, -0.448],
        [0.177, -0.365, -0.289],
        [0.43, -0.135, -0.21],
        [0.282, 0.062, -0.405],
        [-0.278, -0.077, -0.405],
        [0.037, -0.143, -0.475],
        [-0.164, -0.389, -0.262],
        [-0.074, 0.459, -0.178],
        [-0.35, 0.212, -0.283],
        [-0.438, -0.191, -0.139],
        [-0.291, -0.396, 0.078],
        [0.022, -0.496, 0.034],
        [0.214, -0.335, 0.299],
        [0.46, -0.097, 0.165],
        [0.459, 0.18, -0.072],
        [0.231, 0.358, -0.258],
        [-0.493, 0.077, 0.01],
        [-0.339, 0.365, 0.01],
        [-0.403, -0.132, 0.261],
        [-0.32, 0.213, 0.318],
        [0.335, -0.368, 0.0],
        [0.223, 0.438, 0.074],
        [0.362, 0.207, 0.271],
        [0.055, 0.271, 0.414],
        [0.206, -0.052, 0.45],
        [-0.133, -0.016, 0.479],
        [-0.082, 0.454, 0.187],
        [-0.103, -0.326, 0.362],
    ],
    "29": [
        [-0.003, 0.328, -0.376],
        [0.182, -0.368, -0.282],
        [0.414, -0.13, -0.245],
        [0.237, 0.052, -0.434],
        [-0.119, 0.06, -0.48],
        [-0.006, -0.215, -0.449],
        [-0.178, -0.412, -0.215],
        [-0.051, 0.484, -0.104],
        [-0.33, 0.236, -0.288],
        [-0.358, -0.121, -0.324],
        [-0.418, -0.268, -0.023],
        [-0.2, -0.42, 0.178],
        [0.056, -0.495, 0.016],
        [0.196, -0.353, 0.29],
        [0.457, -0.112, 0.166],
        [0.47, 0.139, -0.087],
        [0.281, 0.341, -0.228],
        [-0.494, 0.054, -0.025],
        [-0.338, 0.366, -0.0],
        [-0.383, -0.133, 0.289],
        [-0.365, 0.193, 0.278],
        [0.357, -0.347, -0.005],
        [0.222, 0.436, 0.089],
        [0.394, 0.198, 0.229],
        [0.125, 0.272, 0.398],
        [0.226, -0.048, 0.44],
        [-0.127, 0.08, 0.474],
        [-0.108, 0.419, 0.246],
        [-0.077, -0.242, 0.428],
    ],
    "30": [
        [0.047, 0.278, -0.41],
        [0.172, -0.385, -0.263],
        [0.419, -0.134, -0.231],
        [0.242, 0.002, -0.437],
        [-0.136, 0.042, -0.478],
        [-0.003, -0.232, -0.442],
        [-0.183, -0.419, -0.198],
        [-0.328, 0.374, 0.008],
        [-0.014, 0.473, -0.155],
        [-0.27, 0.281, -0.309],
        [-0.336, -0.152, -0.334],
        [-0.447, -0.221, -0.016],
        [-0.243, -0.41, 0.143],
        [0.05, -0.493, 0.056],
        [0.239, -0.327, 0.289],
        [0.461, -0.104, 0.159],
        [0.474, 0.135, -0.07],
        [0.305, 0.303, -0.252],
        [-0.462, 0.088, -0.162],
        [-0.255, 0.258, 0.341],
        [-0.346, -0.141, 0.328],
        [-0.463, 0.091, 0.159],
        [0.35, -0.354, -0.007],
        [0.26, 0.42, 0.063],
        [0.383, 0.193, 0.255],
        [0.101, 0.286, 0.395],
        [0.223, -0.041, 0.443],
        [-0.101, 0.017, 0.487],
        [-0.05, 0.465, 0.171],
        [-0.048, -0.304, 0.391],
    ],
};
export const cameraPathOptions: CameraPath[] = ["Radial", "Spherical"];

export const cameraOrderOptions: CameraOrder[] = ["Random", "Sequential"];

export const durationOptions = [
    // {
    //     duration: 0.5 * 1000,
    //     label: "2fps",
    // },
    // {
    //     duration: 5 * 1000,
    //     label: "5s",
    // },
    {
        duration: 30 * 1000,
        label: "30s",
    },
    {
        duration: 60 * 1000,
        label: "1 min",
    },
    {
        duration: 120 * 1000,
        label: "2 min",
    },
    {
        duration: 180 * 1000,
        label: "3 min",
    },
    {
        duration: 240 * 1000,
        label: "4 min",
    },
    {
        duration: 300 * 1000,
        label: "5 min",
    },
    {
        duration: 600 * 1000,
        label: "10 min",
    },
    {
        duration: 900 * 1000,
        label: "15 min",
    },
];

export const initialDurationIndex = 2;
