import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "DrawingTimerDensity",
  "data-key": "density"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_select = _resolveComponent("ui-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ui_select, {
      onUpdate: _ctx.update,
      options: _ctx.options,
      label: "Frames",
      value: _ctx.store.density,
      vertical: _ctx.vertical,
      "small-label": ""
    }, null, 8, ["onUpdate", "options", "value", "vertical"])
  ]))
}