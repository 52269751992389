
import { computed, defineComponent } from "vue";
import useTool from "@/composables/use-tool";
import { MixedStateValueList } from "@/types";

export default defineComponent({
    name: "UiList",
    props: {
        options: {
            type: Array,
            required: true,
        },
        value: {
            type: Object,
            required: true,
        },
        labelKey: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const mixedV: MixedStateValueList = {
            id: null,
            [props.labelKey]: "Mixed",
        };

        const { localValue, isMixed } = useTool(props, emit, mixedV);

        const allOptions = computed(() => {
            if (isMixed.value) {
                return [mixedV, ...props.options];
            } else {
                return props.options;
            }
        });

        return {
            localValue,
            allOptions,
        };
    },
});
