import { SketchfabApi } from "@/types/artboard-area";
import { PostProcessingSettings } from "@/types/sketchfab-types";

const setPostProcessing = (
    api: SketchfabApi,
    settings: PostProcessingSettings
): Promise<void> => {
    return new Promise((resolve) => {
        api.setPostProcessing(settings, () => {
            resolve();
        });
    });
};

export default {
    setPostProcessing,
};
