import { Coordinate, SketchfabApi } from "@/types";

const clearAnnotations = (api: SketchfabApi): Promise<void> => {
    return new Promise((resolve) => {
        api.getAnnotationList((err: Error, annotations: any[]) => {
            if (!err) {
                for (let n = annotations.length - 1; n >= 0; n--) {
                    api.removeAnnotation(n);
                }
                resolve();
            } else {
                console.log(err);
            }
        });
    });
};

const createAnnotation = (
    api: SketchfabApi,
    position: Coordinate,
    eye: Coordinate,
    target: Coordinate
): Promise<void> => {
    return new Promise((resolve) => {
        api.createAnnotationFromWorldPosition(
            position,
            eye,
            target,
            "",
            "",
            () => {
                resolve();
            }
        );
    });
};

export default {
    clearAnnotations,
    createAnnotation,
};
