import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "Toolbar" }
const _hoisted_2 = { class: "Toolbar__section" }
const _hoisted_3 = { class: "Toolbar__section Toolbar__section--upload-your-artwork" }
const _hoisted_4 = { class: "Toolbar__section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon_button = _resolveComponent("ui-icon-button")!
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_help = _resolveComponent("help")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ui_icon_button, {
        id: "add-scan",
        disabled: !_ctx.canAddArtboard,
        onClick: _ctx.addArtboard,
        "icon-id": "add",
        label: _ctx.mobile ? 'Add' : 'Add another model',
        capitalize: ""
      }, null, 8, ["disabled", "onClick", "label"]),
      _createVNode(_component_ui_icon_button, {
        id: "replace-scan",
        onClick: _ctx.replaceArtboard,
        "icon-id": "replace",
        label: _ctx.mobile ? 'Replace' : 'Replace this model',
        disabled: !_ctx.artboardStore.current || !_ctx.canAddArtboard,
        capitalize: ""
      }, null, 8, ["onClick", "label", "disabled"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ui_button, {
        id: "upload-artwork",
        label: "Upload your artwork",
        onClick: _ctx.uploadArtwork
      }, null, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ui_icon_button, {
        id: "save-to-collection",
        onClick: _ctx.saveToCollection,
        "icon-id": "save-to-collection",
        label: "Save to Collection",
        border: "",
        vertical: _ctx.mobile
      }, null, 8, ["onClick", "vertical"]),
      _createVNode(_component_ui_icon_button, {
        id: "favorite",
        onClick: _ctx.favorite,
        "icon-id": "favorite",
        label: "Add to Favorites",
        border: "",
        vertical: _ctx.mobile
      }, null, 8, ["onClick", "vertical"])
    ]),
    _createVNode(_component_help)
  ]))
}