import { mixedState } from "@/data/settings/settings";

export const isEqual = (initialValue: any, thisValue: any) => {
    if (typeof initialValue === "object") {
        if (!initialValue && !thisValue) {
            return true;
        } else if (!initialValue || !thisValue) {
            return false;
        } else {
            return thisValue.id === initialValue.id;
        }
    } else {
        return thisValue !== mixedState && thisValue === initialValue;
    }
};

export const clone = (obj: any) => {
    return JSON.parse(JSON.stringify(obj));
};
