import { MaterialChannel, RGBColor, SketchfabMaterial } from "../../../types";
import { SketchfabApi } from "@/types";

let materialList: SketchfabMaterial[];

const storeMaterialList = (api: SketchfabApi): Promise<void> => {
    return new Promise((resolve) => {
        api.getMaterialList(
            (err: Error, theMaterialList: SketchfabMaterial[]) => {
                if (err) {
                    console.log(err.stack);
                } else {
                    if (!materialList) {
                        materialList = theMaterialList;
                    }
                    resolve();
                }
            }
        );
    });
};

const getSceneMaterial = (
    materialName: string
): SketchfabMaterial | undefined => {
    return materialList.find((mtl) => mtl.name === materialName);
};

const setMaterial = (
    api: SketchfabApi,
    theMaterial: SketchfabMaterial
): Promise<void> => {
    return new Promise((resolve) => {
        api.setMaterial(JSON.parse(JSON.stringify(theMaterial)), () => {
            resolve();
        });
    });
};

const setMaterialChannelsOptions = (
    theMaterial: SketchfabMaterial,
    theChannels: { [key: string]: MaterialChannel }
) => {
    // multiple channels provided in an object
    // each channel has an object with properties such as factor or color
    // multiple properties per channel are to be expected
    for (const channelName in theChannels) {
        for (const propertyName in theChannels[channelName]) {
            switch (propertyName) {
                case "colorrgb":
                case "tintrgb":
                case "refractionColorrgb": {
                    break;
                }
                case "tint":
                case "refractionColor":
                case "color": {
                    let rgb = theChannels[channelName][propertyName];
                    if (rgb !== undefined) {
                        if (typeof rgb === "string") rgb = hexToRgb(rgb);

                        const theColor = colorNormalize1(rgb);
                        if (theMaterial.channels[channelName])
                            theMaterial.channels[channelName][propertyName] =
                                theColor;

                        // color and texture values in most channels are mutually exclusive
                        // if both are encountered, sketchfab will throw an error
                        // the SubsurfaceTranslucency channel can hold a texture AND color at the same time
                        // in that channel we shouldn't clear either channel
                        if (
                            channelName !== "SubsurfaceTranslucency" &&
                            theMaterial.channels[channelName] != null
                        ) {
                            delete theMaterial.channels[channelName].texture;
                            delete theMaterial.channels[channelName]
                                .UVTransforms;
                        }
                    }
                    break;
                }
                case "factor": {
                    theMaterial.channels[channelName].factor =
                        theChannels[channelName].factor;
                    break;
                }
                default: {
                    theMaterial.channels[channelName][propertyName] =
                        theChannels[channelName][propertyName];
                    break;
                }
            }
        }
    }
};

const hexToRgb = function (hex: string): number[] {
    const m = hex.match(/^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i);
    if (m) return [parseInt(m[1], 16), parseInt(m[2], 16), parseInt(m[3], 16)];
    else return [0, 128, 255];
};

function componentToHex(c: number) {
    c = Math.round(c);
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex.substring(0, 2);
}

function rgbToHex(rgb: number[]) {
    return (
        "#" +
        componentToHex(rgb[0]) +
        componentToHex(rgb[1]) +
        componentToHex(rgb[2])
    );
}

const convertColorToArray = function (color: RGBColor): number[] {
    return [color.r, color.g, color.b];
};

/**
 * Normalize an RGB value from 0-255 to 0-1. Applies gamma correction
 * @param {array} color an array with three elements: rgb
 * @returns a 0-1 normalized array
 */
const colorNormalize1 = function (color: number[]): number[] {
    const theColor = [0, 0, 0];
    if (color === null || color === undefined) return theColor;
    theColor[0] = (color[0] / 255) ** 2.2;
    theColor[1] = (color[1] / 255) ** 2.2;
    theColor[2] = (color[2] / 255) ** 2.2;
    return theColor;
};

/**
 * Normalize an RGB value from 0-1 to 0-255. Applies gamma correction
 * @param {array} color an array with three elements: rgb
 * @returns a 0-255 normalized array
 */
const colorNormalize255 = function (color: number[]): number[] {
    const theColor = [0, 0, 0];
    if (color === null || color === undefined) return theColor;
    theColor[0] = color[0] ** (1 / 2.2) * 255;
    theColor[1] = color[1] ** (1 / 2.2) * 255;
    theColor[2] = color[2] ** (1 / 2.2) * 255;
    return theColor;
};

const applyMaterialPreset = (
    api: SketchfabApi,
    materialPayload: { [key: string]: MaterialChannel },
    shadingtype: "lit" | "matcap"
) => {
    const excludeNames = ["M_ground", "M_guides"];
    api.setShadingStyle("pbr", { type: shadingtype });
    materialList.forEach((mtl) => {
        if (excludeNames.includes(mtl.name)) return;
        setMaterialChannelsOptions(mtl, materialPayload);
        setMaterial(api, mtl);
    });
};

export default {
    rgbToHex,
    storeMaterialList,
    setMaterial,
    setMaterialChannelsOptions,
    getSceneMaterial,
    colorNormalize1,
    convertColorToArray,
    applyMaterialPreset,
};
