
import { computed, defineComponent } from "vue";
import { Tool, DisplaySettingsValue } from "@/types";
import UiCheckbox from "@/components/ui/checkbox/Checkbox.vue";
import { useArtboardStore } from "@/stores/artboards";

export default defineComponent({
    name: "DisplaySettingsDrawingAidToggle",
    components: { UiCheckbox },

    setup(props) {
        const store = useArtboardStore();
        const boundingBoxTool: Tool = {
            type: "checkbox",
            key: "boundingBox",
            options: {
                title: "",
            },
            forIndividualArtboard: false,
        };
        const boundingBoxIconSet = {
            checked: "circle-guide-on",
            unchecked: "circle-guide-off",
            mixed: "circle-guide-mixed",
        };
        const boundingBoxValue = computed(() => {
            return store.getDisplaySettingsValue(boundingBoxTool.key);
        });
        const boundingBoxUpdate = (value: DisplaySettingsValue) => {
            store.setDisplaySettingsValue(boundingBoxTool.key, value);
        };
        const groundPlaneTool: Tool = {
            type: "checkbox",
            key: "groundPlane",
            options: {
                title: "",
            },
            forIndividualArtboard: false,
        };
        const groundPlaneIconSet = {
            checked: "circle-grid-on",
            unchecked: "circle-grid-off",
            mixed: "circle-grid-mixed",
        };
        const groundPlaneValue = computed(() => {
            return store.getDisplaySettingsValue(groundPlaneTool.key);
        });
        const groundPlaneUpdate = (value: DisplaySettingsValue) => {
            store.setDisplaySettingsValue(groundPlaneTool.key, value);
        };
        return {
            boundingBoxTool,
            boundingBoxUpdate,
            boundingBoxValue,
            boundingBoxIconSet,
            groundPlaneTool,
            groundPlaneUpdate,
            groundPlaneValue,
            groundPlaneIconSet,
        };
    },
});
