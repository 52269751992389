import { SketchfabApi } from "@/types/artboard-area";
import { BackgroundSettings } from "@/types/sketchfab-types";

const setBackground = (
    api: SketchfabApi,
    settings: BackgroundSettings
): Promise<void> => {
    return new Promise((resolve) => {
        const normalizedColor = settings.color.map((c) => c / 255);
        settings.color = normalizedColor;
        api.setBackground(settings, () => {
            resolve();
        });
    });
};

export default {
    setBackground,
};
