
import { defineComponent } from "vue";
import DrawingTimerCameraPath from "@/components/drawing-timer/DrawingTimerCameraPath.vue";
import DrawingTimerDensity from "@/components/drawing-timer/DrawingTimerDensity.vue";
import DrawingTimerCameraOrder from "@/components/drawing-timer/DrawingTimerCameraOrder.vue";

export default defineComponent({
    name: "DrawingTimerTools",
    props: {
        vertical: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    components: {
        DrawingTimerCameraOrder,
        DrawingTimerDensity,
        DrawingTimerCameraPath,
    },
});
