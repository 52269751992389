
import { useArtboardStore } from "@/stores/artboards";
import { useUiStore } from "@/stores/ui";
import { computed, defineComponent } from "vue";
import UiIconButton from "@/components/ui/icon-button/IconButton.vue";
import UiButton from "@/components/ui/button/Button.vue";
import messaging from "@/tools/messaging";
import Help from "@/components/help/Help.vue";
import useBreakpoint from "@/composables/use-breakpoint";

export default defineComponent({
    name: "Toolbar",
    components: { Help, UiButton, UiIconButton },
    setup() {
        const artboardStore = useArtboardStore();
        const uiStore = useUiStore();

        const { mobile } = useBreakpoint();

        const canAddArtboard = computed(
            () => artboardStore.selected.length < 4
        );

        const addArtboard = () => {
            uiStore.replaceMode = false;
            messaging.sendMessage({ action: "request-scan" });
        };

        const replaceArtboard = () => {
            uiStore.replaceMode = true;
            messaging.sendMessage({ action: "request-scan" });
        };

        const saveToCollection = () => {
            messaging.sendMessage({ action: "save-to-collection" });
        };

        const favorite = () => {
            messaging.sendMessage({ action: "favorite" });
        };

        const uploadArtwork = () => {
            messaging.sendMessage({ action: "upload-artwork" });
        };

        return {
            artboardStore,
            addArtboard,
            replaceArtboard,
            canAddArtboard,
            saveToCollection,
            favorite,
            uploadArtwork,
            mobile,
        };
    },
});
