
import { computed, defineComponent } from "vue";
import UiSelect from "@/components/ui/select/Select.vue";
import { useDrawingTimerStore } from "@/stores/drawing-timer";
import { CameraPath } from "@/types";
import { cameraPathOptions } from "@/data/drawing-timer";

export default defineComponent({
    name: "DrawingTimerCameraPath",
    components: { UiSelect },
    props: {
        vertical: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup() {
        const store = useDrawingTimerStore();

        const options = computed<CameraPath[]>(() => cameraPathOptions);

        const update = (v: CameraPath) => {
            store.setCameraPath(v);
        };

        return {
            options,
            update,
            store,
        };
    },
});
