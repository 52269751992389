
import { defineComponent, ref, watch } from "vue";
import useBreakpoint from "@/composables/use-breakpoint";

export default defineComponent({
    name: "ArtboardLoadingScreen",
    props: {
        loaded: {
            type: Boolean,
            required: true,
        },
    },
    setup(props) {
        const loadedWithDelay = ref(false);
        const { mobile } = useBreakpoint();

        const delay = 3000;

        watch(
            () => props.loaded,
            () => {
                if (props.loaded) {
                    // Add a delay to hide the loading screen
                    // This should keep the buildup of the scene
                    // hidden to the user.
                    setTimeout(() => {
                        loadedWithDelay.value = true;
                    }, delay);
                }
            }
        );

        return {
            mobile,
            loadedWithDelay,
        };
    },
});
