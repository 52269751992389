import { ArtboardCamera } from "@/types";

export const subtractCameras = (
    c1: ArtboardCamera,
    c2: ArtboardCamera
): ArtboardCamera => {
    return {
        latlon: {
            lat: c1.latlon.lat - c2.latlon.lat,
            lon: c1.latlon.lon - c2.latlon.lon,
        },
        radius: c1.radius - c2.radius,
        target: [
            c1.target[0] - c2.target[0],
            c1.target[1] - c2.target[1],
            c1.target[2] - c2.target[2],
        ],
    };
};

export const sumCameras = (
    c1: ArtboardCamera,
    c2: ArtboardCamera
): ArtboardCamera => {
    return {
        latlon: {
            lat: c1.latlon.lat + c2.latlon.lat,
            lon: c1.latlon.lon + c2.latlon.lon,
        },
        radius: c1.radius + c2.radius,
        target: [
            c1.target[0] + c2.target[0],
            c1.target[1] + c2.target[1],
            c1.target[2] + c2.target[2],
        ],
    };
};

export const hasOffset = (c1: ArtboardCamera, c2: ArtboardCamera): boolean => {
    const offset = subtractCameras(c1, c2);
    const margin = 0.00001;
    const lat = Math.abs(offset.latlon.lat) % 360;
    const lon = Math.abs(offset.latlon.lon) % 360;
    return (
        lat > margin ||
        lon > margin ||
        Math.abs(offset.radius) > margin ||
        Math.abs(offset.target[0]) > margin ||
        Math.abs(offset.target[1]) > margin ||
        Math.abs(offset.target[2]) > margin
    );
};
