import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-loaded", "data-current", "data-focus", "data-inactive-index"]
const _hoisted_2 = { class: "Artboard__content" }
const _hoisted_3 = { class: "Artboard__main" }
const _hoisted_4 = ["id"]
const _hoisted_5 = {
  key: 2,
  class: "Artboard__offset"
}
const _hoisted_6 = {
  key: 3,
  class: "Artboard__alert"
}
const _hoisted_7 = {
  key: 0,
  class: "Artboard__grayed-out"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_artboard_loading_screen = _resolveComponent("artboard-loading-screen")!
  const _component_artboard_header_tools = _resolveComponent("artboard-header-tools")!
  const _component_artboard_footer_tools = _resolveComponent("artboard-footer-tools")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["Artboard", { 'Artboard--event': _ctx.isEvent }]),
    "data-loaded": _ctx.artboard.loaded,
    "data-current": _ctx.isCurrent,
    "data-focus": _ctx.hasFocus,
    "data-inactive-index": _ctx.inactiveIndex,
    ref: "element",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.select && _ctx.select(...args)))
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.disableSketchfab)
          ? (_openBlock(), _createBlock(_component_artboard_loading_screen, {
              key: 0,
              class: "Artboard__loader",
              loaded: _ctx.artboard.loaded
            }, null, 8, ["loaded"]))
          : _createCommentVNode("", true),
        (!_ctx.disableSketchfab)
          ? (_openBlock(), _createElementBlock("iframe", {
              key: 1,
              id: 'artboard-' + _ctx.artboard.id,
              allow: "autoplay; fullscreen; xr-spatial-tracking",
              "xr-spatial-tracking": "",
              "execution-while-out-of-viewport": "",
              "execution-while-not-rendered": "",
              "web-share": "",
              allowfullscreen: "",
              mozallowfullscreen: "true",
              webkitallowfullscreen: "true"
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isCurrent && _ctx.element)
        ? (_openBlock(), _createBlock(_component_artboard_header_tools, {
            key: 0,
            artboard: _ctx.artboard,
            element: _ctx.element
          }, null, 8, ["artboard", "element"]))
        : _createCommentVNode("", true),
      (_ctx.isCurrent)
        ? (_openBlock(), _createBlock(_component_artboard_footer_tools, {
            key: 1,
            artboard: _ctx.artboard
          }, null, 8, ["artboard"]))
        : _createCommentVNode("", true),
      (_ctx.testMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.artboard.offset.latlon), 1))
        : _createCommentVNode("", true),
      (_ctx.testMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6))
        : _createCommentVNode("", true)
    ]),
    (_ctx.grayedOut)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}