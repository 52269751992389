
import { defineComponent, PropType } from "vue";
import { Breadcrumb } from "@/types";

export default defineComponent({
    name: "Breadcrumb",
    components: {},
    props: {
        breadcrumb: {
            type: Object as PropType<Breadcrumb>,
            required: true,
        },
        withArrow: {
            type: Boolean,
            required: true,
        },
    },
    setup(props) {
        const action = () => {
            if (props.breadcrumb.action) {
                props.breadcrumb.action();
            }
        };

        return {
            action,
        };
    },
});
