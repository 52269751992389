import {
    ArtboardCamera,
    Coordinate,
    SketchfabApi,
    SketchfabCamera,
} from "@/types";
import {
    getLatLonFromPos,
    getRadius,
    getPositionFromLatLon,
} from "../general/transform";
import { orthoLensAngle } from "@/data/constants";

const getCamera = function (api: SketchfabApi): Promise<SketchfabCamera> {
    return new Promise((resolve) => {
        api.getCameraLookAt((err: Error, camera: SketchfabCamera) => {
            resolve(camera);
        });
    });
};

const captureCamera = async function (
    api: SketchfabApi
): Promise<ArtboardCamera> {
    const camData: SketchfabCamera = await getCamera(api);
    return {
        target: camData.target,
        latlon: getLatLonFromPos(camData.position, camData.target),
        radius: getRadius(camData.position, camData.target),
        position: camData.position,
    };
};

const setCameraUnconstrained = (
    api: SketchfabApi,
    eye: Coordinate,
    T: Coordinate,
    duration: number
) => {
    if (duration === null || duration === undefined) duration = 1;
    const target = T ? T : [0, 0, 0];
    api.setCameraLookAt(eye, target, duration);
};

const setLensAngle = (
    api: SketchfabApi,
    lensAngle: number,
    applyAutoZoom: boolean
) => {
    const min = 15;
    const max = 120;
    const allowedAngle =
        lensAngle === orthoLensAngle
            ? orthoLensAngle
            : Math.min(max, Math.max(min, lensAngle));
    setFov(api, allowedAngle).then(() => {
        if (applyAutoZoom) {
            lensAngle === orthoLensAngle
                ? autoZoom(api)
                : autoZoom(api, allowedAngle);
        }
    });
};

const setFov = (api: SketchfabApi, fov: number): Promise<void> => {
    return new Promise((resolve) => {
        api.setFov(fov, () => {
            resolve();
        });
    });
};

const getFov = (api: SketchfabApi): Promise<number> => {
    return new Promise((resolve) => {
        api.getFov((_err: Error, fov: number) => {
            resolve(fov);
        });
    });
};

const setOrtho = (api: SketchfabApi, ortho: boolean, lensAngle: number) => {
    if (ortho) {
        setFov(api, 0.1).then(() => {
            autoZoom(api);
        });
    } else {
        setFov(api, lensAngle).then(() => {
            autoZoom(api, lensAngle);
        });
    }
};

const autoZoom = (api: SketchfabApi, lensAngle?: number) => {
    getCamera(api).then((camera) => {
        const r = lensAngle ? Math.atan(0.2 / lensAngle) * 600 : 1300;
        const latlon = getLatLonFromPos(camera.position, camera.target);
        const pos = getPositionFromLatLon(latlon, r, camera.target);
        setCameraUnconstrained(api, pos, camera.target, 0.3);
    });
};

const getCameraCenter = (api: SketchfabApi): Promise<Coordinate> => {
    return new Promise((resolve) => {
        api.setEnableCameraConstraints(false, {}, (err: Error) => {
            api.focusOnVisibleGeometries(function (err: Error) {
                if (!err) {
                    api.getCameraLookAt(async function (
                        err: Error,
                        camera: SketchfabCamera
                    ) {
                        resolve(camera.target);
                    });
                }
            });
        });
    });
};
export default {
    captureCamera,
    getFov,
    setFov,
    setOrtho,
    setLensAngle,
    getCameraCenter,
};
