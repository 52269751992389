import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55b5d5e7"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-theme", "data-direction"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "Slider__base" }
const _hoisted_4 = {
  key: 0,
  class: "Slider__center-point"
}
const _hoisted_5 = { class: "Slider__input" }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_slider = _resolveComponent("v-slider")!

  return (_openBlock(), _createElementBlock("div", {
    class: "Slider",
    "data-theme": _ctx.theme,
    "data-direction": _ctx.direction
  }, [
    (_ctx.title.length > 0)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.centerPoint)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4))
        : _createCommentVNode("", true),
      _createVNode(_component_v_slider, {
        modelValue: _ctx.localValue,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue) = $event)),
        color: _ctx.color,
        min: _ctx.min,
        max: _ctx.max,
        step: _ctx.step,
        direction: _ctx.direction
      }, _createSlots({ _: 2 }, [
        (!_ctx.hideInput)
          ? {
              name: "append",
              fn: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  (!_ctx.isMixed)
                    ? (_openBlock(), _createBlock(_component_v_text_field, {
                        key: 0,
                        modelValue: _ctx.localValue,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
                        modelModifiers: { number: true },
                        type: "number",
                        min: _ctx.min,
                        max: _ctx.max,
                        step: _ctx.step,
                        style: {"width":"80px"},
                        density: "compact",
                        "hide-details": ""
                      }, null, 8, ["modelValue", "min", "max", "step"]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_6, "Mixed"))
                ])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["modelValue", "color", "min", "max", "step", "direction"])
    ])
  ], 8, _hoisted_1))
}