
import { computed, defineComponent } from "vue";
import UiSelect from "@/components/ui/select/Select.vue";
import { useDrawingTimerStore } from "@/stores/drawing-timer";
import {
    radialCameraVectors,
    sphericalCameraVectors,
} from "@/data/drawing-timer";

export default defineComponent({
    name: "DrawingTimerDensity",
    components: { UiSelect },
    props: {
        vertical: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup() {
        const store = useDrawingTimerStore();

        const options = computed<string[]>(() => {
            if (store.cameraPath === "Spherical") {
                return Object.keys(sphericalCameraVectors);
            } else {
                return Object.keys(radialCameraVectors);
            }
        });

        const update = (v: string) => {
            store.density = v;
            // update the current frame to 1
            store.currentFrame = 1;
        };

        return {
            store,
            options,
            update,
        };
    },
});
