import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f41aeed"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ToolsContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_expansion_title = _resolveComponent("expansion-title")!
  const _component_reset_button = _resolveComponent("reset-button")!
  const _component_display_settings_tool = _resolveComponent("display-settings-tool")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!

  return (_ctx.activeTools.length > 0)
    ? (_openBlock(), _createBlock(_component_v_expansion_panel, {
        key: 0,
        "data-section": _ctx.section.title
      }, {
        title: _withCtx(() => [
          _createVNode(_component_expansion_title, {
            title: _ctx.section.title,
            icon: _ctx.section.icon,
            "has-offset": _ctx.hasOffset
          }, null, 8, ["title", "icon", "has-offset"])
        ]),
        text: _withCtx(() => [
          _createVNode(_component_reset_button, {
            disabled: _ctx.resetDisabled,
            onClick: _ctx.reset
          }, null, 8, ["disabled", "onClick"]),
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeTools, (tool, index) => {
              return (_openBlock(), _createBlock(_component_display_settings_tool, {
                key: index,
                tool: tool
              }, null, 8, ["tool"]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["data-section"]))
    : _createCommentVNode("", true)
}