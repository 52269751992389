
import { computed, defineComponent } from "vue";
import Artboard from "./artboard/Artboard.vue";
import ArtboardAreaTools from "@/components/artboard-area/ArtboardAreaTools.vue";
import { Artboard as ArtboardType } from "@/types";
import { useUiStore } from "@/stores/ui";
import { useArtboardStore } from "@/stores/artboards";
import DisplaySettingsLightLatitude from "@/components/settings/DisplaySettingsLightLatitude.vue";
import DisplaySettingsLightLongitude from "@/components/settings/DisplaySettingsLightLongitude.vue";
import DisplaySettingsDrawingAidToggle from "@/components/settings/DisplaySettingsDrawingAidToggle.vue";
import HorizontalVertical from "@/components/ui/horizontal-vertical/HorizontalVertical.vue";
import UiIconButton from "@/components/ui/icon-button/IconButton.vue";
import useBreakpoint from "@/composables/use-breakpoint";

export default defineComponent({
    name: "ArtboardArea",
    components: {
        UiIconButton,
        HorizontalVertical,
        ArtboardAreaTools,
        Artboard,
        DisplaySettingsLightLatitude,
        DisplaySettingsLightLongitude,
        DisplaySettingsDrawingAidToggle,
    },
    props: undefined,
    setup() {
        const { mobile } = useBreakpoint();
        const store = useArtboardStore();
        const uiStore = useUiStore();

        const hasFocus = (artboard: ArtboardType) =>
            store.artboardWithFocus === artboard;

        const fullscreen = computed(() => uiStore.fullscreenMode);

        const deselectArtboard = () => {
            store.deselectArtboard();
            uiStore.liveDrawingPanel = false;
        };

        const openDrawingTimer = () => {
            if (store.selected.length > 0) {
                store.general.layout = "focus";
                if (!store.current) {
                    store.current = store.previousCurrent
                        ? store.previousCurrent
                        : store.selected[0];
                }
                uiStore.liveDrawingPanel = true;
            }
        };

        return {
            hasFocus,
            store,
            uiStore,
            openDrawingTimer,
            mobile,
            fullscreen,
            deselectArtboard,
        };
    },
});
