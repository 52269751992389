import { defineStore } from "pinia";
import { CameraOrder, CameraPath } from "@/types";
import { durationOptions, initialDurationIndex } from "@/data/drawing-timer";

interface DrawingTimerState {
    isPlaying: boolean;
    currentFrame: number;
    duration: number;
    density: string;
    cameraPath: CameraPath;
    cameraOrder: CameraOrder;
    timeToNextFrame: number;
}

export const useDrawingTimerStore = defineStore("drawingTimer", {
    state: () => {
        return {
            isPlaying: false,
            currentFrame: 1,
            duration: durationOptions[initialDurationIndex].duration,
            density: "8",
            cameraOrder: "Sequential",
            cameraPath: "Radial",
            timeToNextFrame: durationOptions[initialDurationIndex].duration,
        } as DrawingTimerState;
    },
    getters: {
        densityNumber(state): number {
            return parseInt(state.density);
        },
    },
    actions: {
        setCameraPath(path: CameraPath) {
            if (path === "Spherical") {
                this.density = "12";
            } else if (path === "Radial") {
                this.density = "8";
            }
            this.cameraPath = path;
        },
        nextFrame() {
            this.currentFrame++;
            if (this.currentFrame > this.densityNumber) {
                this.currentFrame = 1;
            }
        },
        previousFrame() {
            this.currentFrame--;
            if (this.currentFrame < 1) {
                this.currentFrame = this.densityNumber;
            }
        },
    },
});
