import { breakpointLaptop } from "@/data/constants";
import { useBreakpoints } from "@vueuse/core";

export default function useBreakpoint() {
    const breakpoints = useBreakpoints({
        laptop: breakpointLaptop,
        artboardWideHeader: 1200,
        drawingTimerWideLayout: 1150,
    });

    const laptop = breakpoints.greaterOrEqual("laptop");
    const mobile = breakpoints.smaller("laptop");
    const wideArtboardHeader = breakpoints.greaterOrEqual("artboardWideHeader");
    const wideDrawingTimer = breakpoints.greaterOrEqual(
        "drawingTimerWideLayout"
    );
    const semiWideDrawingTimer = breakpoints.between(
        "artboardWideHeader",
        "drawingTimerWideLayout"
    );

    return {
        laptop,
        mobile,
        wideArtboardHeader,
        wideDrawingTimer,
        semiWideDrawingTimer,
    };
}
