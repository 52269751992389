import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3fef3b0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "Breadcrumb__arrow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["Breadcrumb", { 'Breadcrumb--action': _ctx.breadcrumb.action }])
  }, [
    _createElementVNode("div", {
      class: "Breadcrumb__label",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args)))
    }, _toDisplayString(_ctx.breadcrumb.item.title), 1),
    (_ctx.withArrow)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true)
  ], 2))
}