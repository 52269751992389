import { Tool } from "@/types";
import environments from "@/data/settings/environments";
import materials from "@/data/settings/materials";

interface Section {
    title: string;
    icon: string;
    tools: Tool[];
}

export const sections: Section[] = [
    {
        title: "Key Light",
        icon: "light",
        tools: [
            {
                key: "intensity",
                type: "slider",
                options: {
                    min: 0,
                    max: 3,
                    step: 0.1,
                    title: "Intensity",
                },
                forIndividualArtboard: true,
            },
            {
                key: "latitude",
                type: "slider",
                options: {
                    min: -90,
                    max: 90,
                    step: 1,
                    title: "Inclination",
                },
                forIndividualArtboard: true,
            },
            {
                key: "longitude",
                type: "slider",
                options: {
                    min: 0,
                    max: 360,
                    step: 1,
                    title: "Rotation",
                },
                forIndividualArtboard: true,
            },
            {
                key: "shadows",
                type: "checkbox",
                options: {
                    title: "Shadows",
                },
                forIndividualArtboard: true,
            },
        ],
    },
    {
        title: "Environment Light",
        icon: "environment",
        tools: [
            {
                key: "environment",
                type: "list",
                options: {
                    options: environments,
                    labelKey: "name",
                    title: "Environment",
                },
                forIndividualArtboard: true,
            },
            {
                key: "environmentRotation",
                type: "slider",
                options: {
                    min: 0,
                    max: 360,
                    step: 1,
                    title: "Environment Rotation",
                },
                forIndividualArtboard: true,
            },
            {
                key: "environmentIntensity",
                type: "slider",
                options: {
                    min: 0,
                    max: 2,
                    step: 0.01,
                    title: "Environment Intensity",
                },
                forIndividualArtboard: true,
            },
            // {
            //     key: "shadowEnabled",
            //     type: "checkbox",
            //     options: {
            //         title: "Enable Shadows",
            //     },
            //     forIndividualArtboard: true,
            // },
            // {
            //     key: "lightIntensity",
            //     type: "slider",
            //     options: {
            //         min: 0,
            //         max: 5,
            //         step: 0.1,
            //         title: "Light Intensity",
            //     },
            //     forIndividualArtboard: true,
            // },
        ],
    },
    {
        title: "Material",
        icon: "material",
        tools: [
            {
                key: "material",
                type: "list",
                options: {
                    options: materials,
                    labelKey: "name",
                    title: "Material",
                },
                forIndividualArtboard: true,
            },
        ],
    },
    {
        title: "Camera",
        icon: "camera",
        tools: [
            {
                key: "lensAngle",
                type: "slider",
                options: {
                    min: 15,
                    max: 120,
                    step: 1,
                    title: "Angle of View",
                },
                forIndividualArtboard: true,
            },
            {
                key: "lensAngleOrtho",
                type: "checkbox",
                options: {
                    title: "Ortho",
                },
                forIndividualArtboard: true,
            },
        ],
    },
    {
        title: "Model Rotation",
        icon: "rotate_90",
        tools: [
            {
                key: "rotationX",
                type: "slider",
                options: {
                    min: -180,
                    max: 180,
                    step: 1,
                    title: "Rotation X",
                },
                forIndividualArtboard: true,
            },
            {
                key: "rotationY",
                type: "slider",
                options: {
                    min: -180,
                    max: 180,
                    step: 1,
                    title: "Rotation Y",
                },
                forIndividualArtboard: true,
            },
            {
                key: "rotationZ",
                type: "slider",
                options: {
                    min: -180,
                    max: 180,
                    step: 1,
                    title: "Rotation Z",
                },
                forIndividualArtboard: true,
            },
        ],
    },
    {
        title: "Drawing Aids",
        icon: "grid",
        tools: [
            {
                key: "boundingBox",
                type: "checkbox",
                options: {
                    title: "Bounding Box",
                },
                forIndividualArtboard: true,
            },
            {
                key: "groundPlane",
                type: "checkbox",
                options: {
                    title: "Ground Plane",
                },
                forIndividualArtboard: true,
            },
        ],
    },
];

export const allTools: Tool[] = sections.reduce((acc, section) => {
    return [...acc, ...section.tools];
}, [] as Tool[]);
