import { computed, ref, watch } from "vue";
import { mixedState } from "@/data/settings/settings";
import { MixedStateValue } from "@/types";

export default function useTool(
    props: { value: unknown },
    emit: (event: string, arg: unknown) => void,
    mixedStateValue: MixedStateValue
) {
    const localValue = ref<unknown>(props.value);

    const blockCascadingToolHandling = ref(false);
    const blockCascadingSelectionChange = ref(false);

    const isMixed = computed(() => props.value === mixedState);

    const color = computed(() => {
        if (isMixed.value) {
            return "grey";
        } else {
            return "#539aaf";
            // return "var(--color-bim-1)";
        }
    });

    const atSelectMixed = () => {
        localValue.value = true;
    };

    const t = 500;

    // the watch triggers on
    // - when a model is selected / loaded (since the tools panel already exists)
    // / or the selection changes (current or all)
    watch(
        () => props.value,
        () => {
            if (!blockCascadingSelectionChange.value) {
                blockCascadingToolHandling.value = true;
                updateAfterSelectionChange();
                setTimeout(() => (blockCascadingToolHandling.value = false), t);
            }
        }
    );

    // this triggers on direct tool (slider / checkbox / select) handling
    watch(
        () => localValue.value,
        () => {
            if (!blockCascadingToolHandling.value) {
                blockCascadingSelectionChange.value = true;
                updateAfterToolhandling();
                setTimeout(
                    () => (blockCascadingSelectionChange.value = false),
                    t
                );
            }
        }
    );

    const updateAfterToolhandling = () => {
        // prevent sending id null from List
        if (localValue.value !== null) {
            emit("tool-update", localValue.value);
        }
    };

    const updateAfterSelectionChange = () => {
        if (isMixed.value) {
            localValue.value = mixedStateValue;
        } else {
            localValue.value = props.value;
        }
    };

    return {
        localValue,
        isMixed,
        color,
        atSelectMixed,
    };
}
