
import { defineComponent } from "vue";
import UiIconButton from "@/components/ui/icon-button/IconButton.vue";
import UiDeviation from "@/components/ui/deviation/Deviation.vue";

export default defineComponent({
    name: "ExpansionTitle",
    components: {
        UiDeviation,
        UiIconButton,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        hasOffset: {
            type: Boolean,
            required: true,
        },
    },
});
