
import { defineComponent } from "vue";
import { useDrawingTimerStore } from "@/stores/drawing-timer";
import UiIconButton from "@/components/ui/icon-button/IconButton.vue";

export default defineComponent({
    name: "DrawingTimerFrameControl",
    components: { UiIconButton },
    setup(props, { emit }) {
        const store = useDrawingTimerStore();

        const previous = () => {
            if (store.isPlaying) emit("reset-timer");
            store.previousFrame();
        };

        const next = () => {
            if (store.isPlaying) emit("reset-timer");
            store.nextFrame();
        };
        return {
            store,
            previous,
            next,
        };
    },
});
